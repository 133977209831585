import * as React from 'react';
import {useEffect} from 'react';
import Snackbar from '@mui/material/Snackbar';
import {AppContext} from "../../../context/app-context/app-context";
import {Alert} from "@mui/material";

export default function Info() {

    const {appData} = React.useContext(AppContext);

    const [open, setOpen] = React.useState(false);
    const [transition] = React.useState(undefined);

    useEffect(() => {
        setOpen(true);
    }, [appData.info])

    const handleClose = () => {
        setOpen(false);
    };

    if (appData.info === undefined || appData.info === null || appData.info.info === undefined || appData.info.info === null || appData.info === '') {
        return <></>;
    }

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={transition}
            message={appData.info?.info ?? ""}
            key={transition ? transition.name : ''}
        >
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                {appData.info?.info ?? ""}
            </Alert>
        </Snackbar>
    );
}
