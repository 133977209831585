const PREFIX = 'contact-info';

export const classes = {
    title: `${PREFIX}-title`,
    subtitle: `${PREFIX}-subtitle`,
    body: `${PREFIX}-body`,
    separator: `${PREFIX}-separator`,
    imgSize: `${PREFIX}-img-size`,
    infoWrapper: `${PREFIX}-info-wrapper`
};
