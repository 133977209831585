import type {AppData} from "../data/app-data";

export const updateSelectedElements = (state: AppData, action) => {
    const newSelectedElement = action.payload.newSelectedElement;
    let selectedElements = state.selectedElements;
    let areSelectedElementsDefined = selectedElements !== undefined && selectedElements !== null;
    let elementAlreadyAdded = selectedElements.find(element => element === newSelectedElement) !== undefined;
    if(areSelectedElementsDefined && elementAlreadyAdded) {
        return selectedElements.filter(element => element !== newSelectedElement)
    }
    return selectedElements.concat(newSelectedElement);
}
