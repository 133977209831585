import {useAuth} from "react-oidc-context";
import {useApi} from "../../../context/api-context/api-context";

export const useTechnicianApi = () => {
    const auth = useAuth();
    const api = useApi();

    const findOrdersForTechnician = async (email: string, availabilityDate: Date) => {
        if (!auth.user?.access_token) {
            throw new Error('Access token is not available.');
        }

        try {
            const response = await api.post('/technician/order/list',
                {
                    email: email,
                    availabilityDate: availabilityDate
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                });
            return response.data;
        } catch (error) {
            console.error('Error fetching secure data:', error);
            throw error;
        }
    };

    return {findOrdersForTechnician};
};
