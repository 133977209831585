const PREFIX = 'home';

export const classes = {
    header: `${PREFIX}-header`,
    nav: `${PREFIX}-nav`,
    navLink: `${PREFIX}-nav-link`,
    main: `${PREFIX}-main`,
    logoWrapper: `${PREFIX}-logo-wrapper`,
    logo: `${PREFIX}-logo`,
    services: `${PREFIX}-services`,
    serviceItem: `${PREFIX}-service-item`,
    icon: `${PREFIX}-icon`,
    serviceItemHoverIcon: `${PREFIX}-service-item-hover-icon`,
    mobileMenuButton: `${PREFIX}-mobile-menu-button`
};
