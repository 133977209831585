import React from "react";
import {Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {classes} from "./content-changer-style";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const StyledTypography = styled(Typography)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: "normal",
    letterSpacing: "none",
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    [`& svg`]: {
        paddingRight: 10,
        fontSize: "1.5rem",
        color: theme.palette.primary.main,
    },
}));

const ContentChanger = ({contentLength, index, changeIndex, children}) => {

    function changeDescription(change) {
        let newIndex = index + change;
        if (newIndex < 0) {
            newIndex = contentLength - 1;
        } else if (newIndex >= contentLength) {
            newIndex = 0;
        }
        changeIndex(newIndex);
    }

    return (
        <>
            <Stack direction={"column"}
                   justifyContent={"center"}
                   alignItems={"flex-start"}
            >
                {children}
            </Stack>
            <StyledTypography variant={"h6"} className={`${classes.textSpecification} ${classes.textNavigation}`}>
                <ArrowCircleLeftIcon fontSize={"inherit"} style={{cursor: "pointer"}}
                                     onClick={() => changeDescription(-1)}
                />
                {`${index + 1}/${contentLength}`}
                <ArrowCircleRightIcon fontSize={"inherit"} style={{cursor: "pointer", paddingLeft: 10}}
                                      onClick={() => changeDescription(1)}
                />
            </StyledTypography>
        </>
    )
}

export default ContentChanger;
