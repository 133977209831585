import React, {useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import {classes} from "./gallery-content-description-style";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import HomeIcon from '@mui/icons-material/Home';
import ContentChanger from "../../common/content-changer/content-changer";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";

const StyledGalleryContentDescription = styled(Grid)(({theme}) => ({
    direction: 'row',
    paddingRight: 10,
    [theme.breakpoints.down('md')]: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    [`& .${classes.text}`]: {
        lineHeight: '1.6rem',
        paddingBottom: 10,
        [theme.breakpoints.up('sm')]: {
            maxHeight: '20vh',
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 20,
        },
    },
    [`& .${classes.textTitle}`]: {
        height: 45,
        maxHeight: 50,
        overflowY: 'auto',
        paddingBottom: 10,
    },
    [`& .${classes.descriptionAttributeText}`]: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'normal',
        letterSpacing: "none",
        textTransform: "none",
        paddingTop: 10,
        paddingBottom: 10,
        [`& svg`]: {
            paddingRight: 10,
            fontSize: '1.5rem',
            color: theme.palette.primary.main,
        }
    },
}));

const GalleryContentDescription = (props) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translationGallery = translation.gallery;
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [descriptionIndex, setDescriptionIndex] = useState(0);

    return (
        <StyledGalleryContentDescription
            id={'gallery-content-description'}
            item
            container
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            direction={!isSmallScreen ? "column" : "row"}
            xs={props.ratio.small}
            sm={props.ratio.small}
            md={props.ratio.middle}
            lg={props.ratio.large}
            xl={props.ratio.large}
        >
            <ContentChanger
                contentLength={translationGallery.galleryContent.length}
                index={descriptionIndex}
                changeIndex={setDescriptionIndex}
            >
                <Typography variant={"h6"}
                            className={classes.textTitle}>{translationGallery.galleryContent[descriptionIndex].textTitle}</Typography>
                <Typography variant={"h6"}
                            className={classes.textTitle}>{translationGallery.galleryContent[descriptionIndex].textSubTitle}</Typography>
                <Typography variant={"h6"}>{translationGallery.deliveryTime}</Typography>
                <Typography variant={"h6"} className={classes.descriptionAttributeText}>
                    <QueryBuilderIcon fontSize={"inherit"}/>
                    {translationGallery.galleryContent[descriptionIndex].deliveryTime}
                </Typography>
                <Typography variant={"h6"}>{translationGallery.workArea}</Typography>
                <Typography variant={"h6"} className={classes.descriptionAttributeText}>
                    <HomeIcon fontSize={"inherit"}/>
                    {translationGallery.galleryContent[descriptionIndex].workAreaSize}
                </Typography>
                <Typography variant={"body1"}
                            className={classes.text}>{translationGallery.galleryContent[descriptionIndex].description}</Typography>
            </ContentChanger>
        </StyledGalleryContentDescription>
    )
}

export default GalleryContentDescription;
