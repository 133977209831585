import React from 'react';
import {styled} from '@mui/material/styles';
import Contact from "../contact/contact";
import {FooterMenu} from "../footer-menu/footer-menu";
import Box from "@mui/material/Box";
import {classes as classesApp} from "../app-styles";

const StyledContactAndFooter = styled(Box)(({theme}) => ({

}));

const ContactAndFooterMenu = () => {
    return (
        <StyledContactAndFooter
            className={classesApp.topFooter}
            minHeight="100vh"
            component={"section"}
            display={"flex"}
            flexDirection={"column"}
        >
            <Box flexGrow={1}
                 display={"flex"}
                 alignItems="center"
                 justifyContent="center">
                <Contact/>
            </Box>
            <FooterMenu/>
        </StyledContactAndFooter>
    );
};

export default ContactAndFooterMenu;
