import {styled} from '@mui/material/styles';
import ContactAndFooterMenu from "./contact-and-footer-menu/contact-and-footer-menu";
import React, {useState} from "react";
import {classes} from "./app-styles";
import Home from "./home/home";
import Gallery from "./gallery/gallery";
import ConstructionElements from "./construction-elements/construction-elements";
import Banner from "./banner/banner";

const StyledAppContent = styled('div')(({theme}) => ({
    [`& .${classes.appComponentWrapper}`]: {
        minHeight: "100vh",
    },

    [`& .${classes.gridTextLeftAlign}`]: {
        [theme.breakpoints.down('md')]: {
            paddingTop: '16px',
            paddingBottom: '16px',
            fontSize: "16px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "18px",
        },
        "& h1, h2, h3, h4, h5": {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.background,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
                fontSize: "32px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "36px",
            },
        },
        "& p": {
            textAlign: 'left',
            margin: 0,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "18px",
            },
        }
    },

    [`& .${classes.gridText}`]: {
        [theme.breakpoints.down('md')]: {
            paddingTop: '16px',
            paddingBottom: '16px',
            fontSize: "16px",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "18px",
        },
        "& h1, h2, h3, h4, h5": {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.background,
            [theme.breakpoints.down('md')]: {
                fontSize: "32px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "36px",
            },
        },
        "& p": {
            color: theme.palette.primary.background,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "18px",
            },
        },
        "& span": {
            color: theme.palette.primary.main,
        }
    },

    [`& .${classes.top}`]: {
        backgroundSize: 'cover',
        backgroundColor: 'light' === 'dark' ? '#FCFCFC' : theme.palette.secondary.main,
        overflow: 'hidden',
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },

    [`& .${classes.topMain}`]: {
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#FCFCFC' : theme.palette.secondary.main,
        overflow: 'hidden',
    },

    [`& .${classes.topSection}`]: {
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#FCFCFC' : '#E4E4E4',
        overflow: 'hidden',
        paddingLeft: '8px',
        paddingRight: '8px',
    },

    [`& .${classes.topFooter}`]: {
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#FCFCFC' : theme.palette.secondary.main,
        overflow: 'hidden',
        paddingTop: '16px',
    },

    [`& .${classes.bottom}`]: {
        color: theme.palette.secondary.main,
        backgroundSize: 'cover',
        backgroundColor: theme.palette.mode === 'dark' ? '#EEEEEE' : theme.palette.secondary.background,
        overflow: 'hidden',
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },

}));

const AppContent = () => {

    const [hidden, hideBanner] = useState(true);

    return (
        <StyledAppContent>
            {hidden &&
                <div id={"banner"} style={{minHeight: '100vh', minWidth: '100%', position: 'fixed', zIndex: 100000,}}>
                    <Banner hideBanner={hideBanner}/>
                </div>
            }
            <header></header>
            <article className={classes.appComponentWrapper}>
                <Home/>
                <ConstructionElements/>
                <Gallery/>
                <ContactAndFooterMenu/>
            </article>
            <footer></footer>
        </StyledAppContent>
    );
}

export default AppContent;
