import Grid from "@mui/material/Grid";
import {styled} from '@mui/material/styles';
import React from "react";
import Button from "@mui/material/Button";
import {classes} from "./contact-button-styles"
import {alpha} from "@mui/material";

const StyledContactButton = styled(Grid)(({theme}) => ({

    [`& .${classes.inputButtonMargin}`]: {
        [theme.breakpoints.down('lg')]: {
            padding: '12px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '4px 0px 4px 12px',
        },
    },

    [`& .${classes.inputGridSmallResolutionMargin}`]: {
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
        },
    },

    [`& .${classes.inputLabel}`]: {
        position: 'relative',
        color: alpha(theme.palette.primary.background, 0.87),
        fontSize: '0.75rem',
        fontWeight: 'bold',
        letterSpacing: '2px',
        textTransform: 'uppercase',
    },
}));


const ContactButton = (props) => {
    return (
        <StyledContactButton container
                             item
                             justifyContent={"flex-end"}
                             alignItems={"flex-start"}
                             className={`${classes.inputGridSmallResolutionMargin}`}
        >
            <Grid item xs={12} sm={12} md={9} lg={8} xl={8} className={classes.inputButtonMargin}>
                <Button fullWidth
                        id={props.id}
                        className={`${classes.inputLabel}`}
                        variant="contained"
                        type={"submit"}
                >
                    {props.label}
                </Button>
            </Grid>
        </StyledContactButton>
    );
}

export default ContactButton;
