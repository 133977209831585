import * as React from 'react';
import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {classes} from "./contact-dialog-styles"
import type {ContactData} from "../shared/dto/contact-data";
import {DialogContext} from "../../../context/dialog-context/dialog-context";
import {Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import CancelIcon from "@mui/icons-material/Cancel";
import {AppContext} from "../../../context/app-context/app-context";
import {colorPalette} from "../../../util/color-palette";
import {useContactApi} from "../api/contact-api";
import ContactPersonalData from "../contact-personal-data/contact-personal-data";
import Box from "@mui/material/Box";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";

const ContactDialogStyled = styled(Dialog)(({theme}) => ({
    [`& .${classes.form}`]: {
        display: 'contents'
    },
    [`& .${classes.cancelIcon}`]: {
        padding: 10,
        paddingRight: 25,
        fontSize: '2rem',
        color: colorPalette.main,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    [`& .${classes.titleWrapper}`]: {
        backgroundColor: colorPalette.base
    },
    [`& .${classes.titleContent}`]: {
        maxHeight: 50,
        overflowY: 'auto',
        paddingBottom: 10,
        width: '100%',
        color: colorPalette.main,
        textAlign: 'center',
        fontSize: "0.75rem",
        fontWeight: "bold",
        letterSpacing: "1px",
        textTransform: "uppercase",
        lineHeight: 1.6
    }

}))

const ContactDialog = () => {

    const {sendEmail} = useContactApi();
    const {visible, setVisible} = React.useContext(DialogContext);
    const {setInfo} = React.useContext(AppContext);
    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;

    const onClose = () => {
        setVisible(false);
    }

    const submit = (event) => {
        event.preventDefault()
        if (contact) {
            sendEmail(contact).then(() => (setInfo(translatedContact.action.email.success)));
        } else {
            setInfo(translatedContact.action.email.error);
        }
        setVisible(false);
    }

    const [contact: ContactData, onContactChange] = useState({
        personalData: {
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
        }
    });

    return (
        <ContactDialogStyled open={visible}
                             onClose={onClose}
                             maxWidth={'md'}
                             fullWidth={true}
        >
            <CancelIcon className={classes.cancelIcon}
                        onClick={onClose}
            />
            <DialogTitle className={classes.titleWrapper}>
                <Typography className={classes.titleContent}>
                    Umów instalację
                </Typography>
            </DialogTitle>
            <DialogContent style={{backgroundColor: colorPalette.base}}>
                <Box component={'form'}
                     className={classes.form}
                     noValidate
                     autoComplete="off"
                     onSubmit={submit}
                >
                    <ContactPersonalData contact={contact}
                                         onContactChange={onContactChange}
                    />
                </Box>
            </DialogContent>
        </ContactDialogStyled>
    );
}

export default ContactDialog;
