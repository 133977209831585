import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {classes} from "./footer-menu-middle-item-styles";
import {styled} from "@mui/material/styles";

const StyledFooterMenuMiddleItem = styled(Grid)(({theme}) => ({
    [`& .${classes.middleMenuCategoryItem}`]: {
        cursor: "pointer",
        display: "flex",
        textDecoration: "none",
        color: theme.palette.primary.background,
        lineHeight: "1.8",
        marginBottom: 10,
        marginTop: 10,
        '&:hover': {
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "16px",
            marginTop: "16px",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    [`& .${classes.middleMenuCategoryTitle}`]: {
        color: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        },
        marginBottom: 20,
        marginTop: 20,
    },
}));

const FooterMenuMiddleItem = (props) => {
    return (
        <StyledFooterMenuMiddleItem
            item
            xs={12}
            sm={12}
            md={12 / props.numberOfSections}
            lg={12 / props.numberOfSections}
            xl={12 / props.numberOfSections}
        >
            <Typography variant="h6" className={classes.middleMenuCategoryTitle}>
                {props.menuHeader}
            </Typography>
            {props.menuItems.map((menuItem, index) => (
                <Link key={index} href={menuItem.url}>
                    <Typography variant={"h6"} className={classes.middleMenuCategoryItem}>{menuItem.title}</Typography>
                </Link>
            ))}
        </StyledFooterMenuMiddleItem>
    );
};

export default FooterMenuMiddleItem;
