import React, {useReducer} from "react";
import {AppContext} from "./app-context";
import type {AppDataConnector} from "./data/app-data-connector";
import reduce from "./app-context-reducer";
import {recalculateAvailabilityDays} from "./util/app-context-recalculate-availability-days";

const defaultValue: AppDataConnector = {
    appData: {
        info: undefined,
        selectedConstructionElement: undefined,
        selectedElements: [],
    },
};

export const AppContextProvider = ({children}) => {
    const [appData, action] = useReducer(reduce, defaultValue.appData);

    const getAvailabilityDays = (): Date[] => {
        return recalculateAvailabilityDays();
    };

    const setSelectedConstructionElement = (newSelectedConstructionElement: number) => {
        action({
            type: "UPDATE_APP_DATA_SELECTED_CONSTRUCTION_ELEMENT",
            payload: newSelectedConstructionElement
        });
    };

    const setSelectedElements = (newSelectedElement: string, selected: boolean) => {
        action({
            type: "UPDATE_APP_DATA_SELECTED_ELEMENTS",
            payload: {
                newSelectedElement: newSelectedElement,
                selected: selected
            }
        });
    };

    const setInfo = (info: string) => {
        action({
            type: "SET_INFO",
            payload: {
                info: info,
            }
        });
    };

    const value: AppDataConnector = {
        appData,
        setSelectedConstructionElement,
        setSelectedElements,
        setInfo,
        getAvailabilityDays,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
