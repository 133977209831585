import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'react-oidc-context';
import {CircularProgress} from "@mui/material";

const Callback = () => {
    const oidc = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (oidc.isAuthenticated) {
            navigate('/technician');
        }
    }, [oidc.isAuthenticated, navigate]);

    if (oidc.isLoading) {
        return (<CircularProgress/>);
    }

    if (oidc.error) {
        return <div>Error: {oidc.error.message}</div>;
    }

    return null;
};

export default Callback;
