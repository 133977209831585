import React, {useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@mui/material";
import {useTechnicianApi} from "./api/technician-api";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {classes} from "./technician-styles";
import {useNavigate} from "react-router-dom";
import {Email, Key, LocationOn, Phone} from "@mui/icons-material";
import {AppContext} from "../../context/app-context/app-context";
import type {ServiceOrderDetails} from "../order-managment/shared/dto/service-order-details";

const StyledTechnician = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.primary.background,
    margin: 0,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    [`& .${classes.header}, & .${classes.navLink}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        width: '100%',
        padding: theme.spacing(2, 4),
    },
    [`& .${classes.main}`]: {
        width: '100%',
        flexGrow: 1,
        boxSizing: 'border-box',
        padding: theme.spacing(4),
    },
    [`& .${classes.serviceOrderCard}`]: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.secondary.main,
        borderRadius: '8px',
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
        },
    },
    [`& .${classes.technicianCard}`]: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.secondary.main,
        borderRadius: '8px',
        padding: theme.spacing(3),
        margin: theme.spacing(2, 0),
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
        },
    },
    [`& .${classes.icon}`]: {
        fontSize: '1.5em',
        marginRight: theme.spacing(1),
    },
    [`& .${classes.infoRow}`]: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
        [`& .${classes.header}`]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        [`& .${classes.nav}`]: {
            flexWrap: 'wrap',
            gap: theme.spacing(2),
        },
        [`& .${classes.actionButtons}`]: {
            flexDirection: 'column',
        },
    },
    [`& .${classes.actionButtons}`]: {
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'center',
        margin: theme.spacing(2, 0),
    },
}));

const Technician = () => {
    const auth = useAuth();
    const {getAvailabilityDays} = React.useContext(AppContext);
    const {findOrdersForTechnician} = useTechnicianApi();
    const [error, setError] = useState(null);
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [serviceOrders: ServiceOrderDetails[], setServiceOrders] = useState([]);
    const [technician] = useState({
        name: 'Korsak Siarhei',
        phone: '123-456-789',
        email: 'ksi@beaver-plumbers.com',
        location: 'New York, NY',
    });
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();

    const signOutRedirect = () => {
        const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
        const logoutUri = process.env.REACT_APP_COGNITO_LOGOUT_REDIRECT_URI;
        const cognitoDomain = `https://eu-central-1vflaxge2d.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com`;
        window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
    };

    const handleDateChange = async (event) => {
        const selected = event.target.value;
        setSelectedDate(selected);

        try {
            // Fetch service orders for the technician's email and selected date
            let userEmail = auth.isAuthenticated ? auth.user?.profile.email : technician.email;
            const orders = await findOrdersForTechnician(userEmail, selected);
            const formattedOrders = orders.map(order => ({
                id: order.id,
                hour: order.serviceOrderDetailsDto.hour,
                price: order.serviceOrderDetailsDto.price,
                date: order.serviceOrderDetailsDto.day,
                serviceType: order.serviceOrderTypeDto.name,
                serviceSubtype: order.serviceOrderSubtypeDto.name,
                address: `${order.addressDto.street}, ${order.addressDto.house}/${order.addressDto.houseNumber}, ${order.addressDto.city}, ${order.addressDto.postalCode}`
            }));
            setServiceOrders(formattedOrders);
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                let availabilityDays = getAvailabilityDays();
                setDates(availabilityDays);

                if (availabilityDays.length > 0) {
                    let userEmail = auth.isAuthenticated ? auth.user?.profile.email : technician.email;
                    const initialOrders = await findOrdersForTechnician(userEmail, availabilityDays[0]);
                    const formattedOrders = initialOrders.map(order => ({
                        id: order.id,
                        hour: order.serviceOrderDetailsDto.hour,
                        price: order.serviceOrderDetailsDto.price,
                        date: order.serviceOrderDetailsDto.day,
                        serviceType: order.serviceOrderTypeDto.name,
                        serviceSubtype: order.serviceOrderSubtypeDto.name,
                        address: `${order.addressDto.street}, ${order.addressDto.house}/${order.addressDto.houseNumber}, ${order.addressDto.city}, ${order.addressDto.postalCode}`
                    }));
                    setServiceOrders(formattedOrders);
                    setSelectedDate(availabilityDays[0]);
                }

                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    if (!auth.isAuthenticated) {
        return (
            <StyledTechnician>
                <Button onClick={() => auth.signinRedirect()}>Sign in</Button>
            </StyledTechnician>
        );
    }

    if (auth.isLoading || loading) {
        return (
            <StyledTechnician>
                <CircularProgress/>
            </StyledTechnician>
        );
    }

    if (auth.error) {
        return (
            <StyledTechnician>
                <Box>Encountering error... {auth.error.message}</Box>
            </StyledTechnician>
        );
    }

    if (auth.isAuthenticated) {
        return (
            <StyledTechnician>
                <Box className={classes.main}>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <InputLabel id="appointment-selection-date">Date for service</InputLabel>
                        <Select
                            labelId="appointment-selection-date"
                            id="appointment-selection-date-selector"
                            value={selectedDate}
                            onChange={handleDateChange}
                            label="Date for service"
                        >
                            {dates.map((date) => (
                                <MenuItem value={date} key={date}>{date}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Grid container spacing={3} justifyContent="center">
                        {serviceOrders.length === 0 ? <CircularProgress/> : serviceOrders.map((order, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4}>
                                <Paper className={classes.serviceOrderCard}>
                                    <Typography variant="h6" component="h2">
                                        {order.hour}:00 - {order.serviceType} ({order.serviceSubtype})
                                    </Typography>
                                    <Typography>{order.price} PLN</Typography>
                                    <Typography>{order.date}</Typography>
                                    <Typography>{order.address}</Typography>
                                </Paper>
                                <Paper className={classes.technicianCard}>
                                    <Typography variant="h6" component="h2">
                                        {technician.name}
                                    </Typography>
                                    <Box className={classes.infoRow}>
                                        <Phone className={classes.icon}/>
                                        <Typography>{technician.phone}</Typography>
                                    </Box>
                                    <Box className={classes.infoRow}>
                                        <Email className={classes.icon}/>
                                        <Typography>{auth.user?.profile.email}</Typography>
                                    </Box>
                                    <Box className={classes.infoRow}>
                                        <LocationOn className={classes.icon}/>
                                        <Typography>{technician.location}</Typography>
                                    </Box>
                                    <TokenPopup label="ID Token" value={auth.user?.id_token}/>
                                    <TokenPopup label="Access Token" value={auth.user?.access_token}/>
                                    <TokenPopup label="Refresh Token" value={auth.user?.refresh_token}/>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <div className={classes.actionButtons}>
                    <Button variant="contained"
                            size="medium"
                            onClick={() => signOutRedirect()}
                    >
                        Wyloguj się
                    </Button>
                    <Button variant="contained"
                            size="medium"
                            onClick={() => navigate("/")}
                    >
                        Go to Home
                    </Button>
                </div>
            </StyledTechnician>
        );
    }
};

const TokenPopup = ({label, value}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.infoRow}>
            <Key className={classes.icon}/>
            <Typography>{label}</Typography>
            <Button size="small" onClick={handleClickOpen}>
                Show
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <Typography style={{wordWrap: "break-word"}}>{value}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Technician;
