import about_1 from '../asset/about/about_1.jpg';
import about_2 from '../asset/about/about_2.jpg';
import about_3 from '../asset/about/about_3.jpg';
import about_4 from '../asset/about/about_4.jpg';
import about_5 from '../asset/about/about_5.jpg';
import about_6 from '../asset/about/about_6.jpg';
import english from "../asset/home/navigation/english.png";
import polish from "../asset/home/navigation/polish.png";
import commonTranslate from "./translate-common";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import CabinIcon from "@mui/icons-material/Cabin";
import React from "react";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForestIcon from "@mui/icons-material/Forest";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import FlashOnIcon from "@mui/icons-material/FlashOn";

const translate = {
    options: [
        {
            index: 0,
            value: "plPL",
            label: "PL",
            icon: polish
        },
        {
            index: 1,
            value: "enUS",
            label: "EN",
            icon: english
        },
    ],
    enUS: {
        label: 'EN',
        company: {
            title: commonTranslate.enUS.company,
            email: commonTranslate.enUS.email,
            phone: commonTranslate.enUS.phone,
        },
        home: {
            title: "Home",
            url: "/#home",
            readMore: "Read more",
            company: {
                shortDescription: commonTranslate.enUS.company + ' construction works and heating systems',
                extendedDescription: `
                    We specialize in heating systems and finishing. 
                    We install heat pumps and underfloor heating. 
                    We insulate buildings and help with renovations
                `,
                contactButton: commonTranslate.enUS.action.contactButton,
            },
            header: {
                company: commonTranslate.plPL.company,
                pageMap: 'Page map',
                email: commonTranslate.plPL.email,
            },
            navigation: {
                offer: 'Offer',
                projects: 'Projects',
                contact: 'Contact',
            },
            calculator: {
                currency: commonTranslate.enUS.currency,
                summary: {
                    title: 'Savings calculator:',
                    result: 'Maintenance costs:',
                },
                actions: {
                    title: 'Select available options:',
                    solarPanel: {
                        title: 'Solar panels',
                        description: "Solar panels pomogą zasilić pompę",
                    },
                    heatPump: {
                        title: 'Heat pump',
                        description: "Take care of the house heating through additional thermal insulation",
                    },
                    floorHeating: {
                        title: 'Floor heating',
                        description: "Perfect combination with photovoltaic panels and heat pump",
                    },
                    buildingInsulation: {
                        title: 'Building insulation',
                        description: "The most important piece of the puzzle will allow for maximum savings",
                    }
                },
            },
            carouselContent: [
                {
                    title: 'Solar panels',
                    content: `
                        We specialize in installing photovoltaic panels, switchboards and electric stoves.
                        We assemble on components from companies such as: Solar Edge, Huawei, Gorwatt Solarsofar, Foxess and Hoymiles.
                        And We rely on photovoltaic panels produced by: Sharp, Longi, Canadian Solar, QCell, Jinko and Bifacial.`,
                },
                {
                    title: 'Pure energy',
                    content: `
                        Electricity prices are rising every year, accounting for an increasing proportion of home maintenance costs.
                        With our company, you can cover your energy needs yourself.
                        A well-designed photovoltaic installation reduces the electricity bill almost to zero,
                        at the same time offering the reimbursement of costs incurred in 7-8 years.
                    `,
                },
                {
                    title: 'Building structure',
                    content: `
                        Building structures - construction, superstructures, extensions, adaptations of residential and public buildings in the full range.
                        Replacement and reinforcement of building structures: replacement of ceilings, load-relieving ceilings, lining and strengthening the foundation of buildings.
                    `,
                },
                {
                    title: 'Renovations',
                    content: `
                        Having the design ready, we start the finishing works. Our construction teams,
                        under the supervision of an architect and construction coordinator, they will efficiently implement the interior design.
                        This stage - depending on the size of the investment - will last from one and a half to several months.
                        We know well that a solid base is the basis for the success of any investment, which is why this part is crucial -
                        we take our time and pay great attention to details.
                    `,
                },
                {
                    title: 'Construction supervision',
                    content: `
                        We control investments in terms of compliance of the work performed with the approved project,
                        the conditions defined in the building permit decision and the provisions of the act.
                        Check whether the land development complies with the local spatial development plans and environmental protection requirements.
                        We verify the conditions for the safety of people and property at the stage of design, implementation and maintenance of the property.
                    `,
                }
            ]
        },
        contact: {
            label: 'Contact',
            url: '/#contact',
            shortInfo: 'Beaver Plumbers',
            info: `
            Przykładowy adres firmy
            ul. Rynek 1, 00-000, Wrocław
            Tel. +48 000 000 000
            NIP: 0000000000
            `,
            form: {
                name: {
                    label: 'Name',
                    placeholder: 'Name'
                },
                email: {
                    label: 'Email',
                    placeholder: 'Email'
                },
                phone: {
                    label: 'Phone Number',
                    placeholder: 'Phone'
                },
                message: {
                    label: 'Message',
                    placeholder: 'Message',
                    placeholderMessage: 'Please enter a text here...'
                },
                city: {
                    label: 'City',
                    placeholder: 'City'
                },
                street: {
                    label: 'Street',
                    placeholder: 'Street'
                },
                postalCode: {
                    label: 'Postal Code',
                    placeholder: 'Postal Code'
                },
                house: {
                    label: 'House',
                    placeholder: 'House'
                },
                houseNumber: {
                    label: 'House Number',
                    placeholder: 'House Number'
                },
                sendButton: {
                    label: 'Send',
                    placeholder: 'Send'
                },
                orderButton: commonTranslate.enUS.action.contactButton,
            },
            action: {
                email: {
                    success: 'Email sent successfully',
                    error: 'Error sending email: '
                },
                order: {
                    success: 'Order created successfully',
                }
            }
        },
        about: {
            title: 'About Us',
            url: '/#about',
            content: `
            Beaver Plumbers is a Polish Company that serves clients and workers in Construction, Building projects and Clean Energy. 
            We have built local professional teams our business spreads across the whole Poland.
            `,
            learnMore: 'Learn More',
            cardContent: [
                {
                    title: 'Renewable energy',
                    content: `
                    Renewable energy is energy that is collected from renewable resources, 
                    which are naturally replenished on a human timescale, including carbon neutral sources like sunlight, wind, rain, tides, 
                    waves, and geothermal heat. The term often also encompasses biomass as well.
                    `,
                    img: about_1,
                },
                {
                    title: 'Solar Panel',
                    content: `
                    The term solar panel is used colloquially for a photo-voltaic (PV) module.
                    A PV module is an assembly of photo-voltaic cells mounted in a framework for installation. 
                    Photo-voltaic cells use sunlight as a source of energy and generate direct current electricity. 
                    `,
                    img: about_2,
                },
                {
                    title: 'House planing',
                    content: `
                    A house plan is a set of construction or working drawings (sometimes called blueprints) 
                    that define all the construction specifications of a residential house such as the dimensions, 
                    materials, layouts, installation methods and techniques.
                    `,
                    img: about_3
                },
                {
                    title: 'Electricity',
                    content: `
                    Electricity is the set of physical phenomena associated with the presence and motion of matter that has a property of electric charge. 
                    Electricity is related to magnetism, both being part of the phenomenon of electromagnetism, as described by Maxwell's equations. 
                    `,
                    img: about_4
                },
                {
                    title: 'Renovation',
                    content: `
                    We’ve built relationships with the best, local contractors in your area. 
                    They’re 100% licensed and insured–and they’ll provide milestone updates throughout the build.
                    `,
                    img: about_5
                },
                {
                    title: 'Heating pump',
                    content: `
                    We offer advice on choosing the most optimal heat source in your home or business. 
                    Whether with heating foils, which keep the floor warm, or with infrared heaters, which are the easiest to install.
                    `,
                    img: about_6
                },
            ]
        },
        service: {
            title: 'Service',
            url: '/#service',
            content: `
            Meet our service offer. You can easily choose time when it fits your personal requirements
            `,
            submit: 'Submit',
            timeLine: [
                {
                    title: 'Choose date',
                    content: 'Select appropriate for you date of service',
                    icon: 'calendar',
                },
                {
                    title: 'Write a description',
                    content: 'Name the service event and provide contact information',
                    icon: 'create',
                },
                {
                    title: 'Accept policies',
                    content: 'Submit service event and accept our policies',
                    icon: 'check',
                },
                {
                    title: 'Wait for our contact',
                    content: 'Grab a coffee and wait untill we contact you',
                    icon: 'call',
                },
            ]
        },
        gallery: {
            title: 'Gallery',
            url: '/#gallery',
            header: 'Discover Our Projects',
            headerDescription: `Explore the latest projects we have completed across Poland. 
                    Each one represents a separate and carefully executed investment.`,
            deliveryTime: 'Delivery time:',
            workArea: 'Work area:',
            galleryContent: [
                {
                    title: 'Warsaw',
                    content: 'Heat Pump Installation',
                    textSubTitle: 'Efficient heating and cooling for a single-family home',
                    description: `
                        In this project, we installed a modern air-to-water heat pump responsible for both heating 
                        and cooling a single-family home. The system was integrated with the existing heating system, 
                        providing the client with year-round thermal comfort while saving energy.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g1/0.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g1/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/2.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/3.jpg`,
                    ],
                    deliveryTime: '2 weeks',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `25 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Wrocław',
                    content: 'Underfloor Heating in an Office',
                    textSubTitle: 'Sustainable office heating with a heat pump',
                    description: `
                        We installed an underfloor heating system using an air-to-water heat pump. 
                        The system provides stable heating in the winter and efficient cooling in the summer. 
                        This ensures a comfortable year-round temperature in the office, 
                        improving the efficiency and comfort of the staff.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g2/1.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g2/2.jpg`,
                    ],
                    deliveryTime: '2 weeks',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Szczecin',
                    content: 'Heating System in a Hotel',
                    textSubTitle: 'Individual temperature control in every hotel room',
                    description: `
                        We installed an air-to-water heat pump that enables independent temperature control 
                        in each hotel room. This gives guests full control over their comfort, 
                        while the hotel saves energy, significantly reducing operating costs.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g3/0.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g3/1.jpg`,
                    ],
                    deliveryTime: '1 month',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `30 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Wałbrzych',
                    content: 'Underfloor Heating in a Production Hall',
                    textSubTitle: 'Stable temperature in the production hall with minimal energy consumption',
                    description: `
                        We installed an underfloor heating system in a production hall, powered by an air-to-water heat pump. 
                        The system maintains a constant, comfortable temperature regardless of external conditions, 
                        improving worker comfort and optimizing energy costs.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g4/11.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g4/12.jpg`,
                    ],
                    deliveryTime: '1 month',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `70 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdańsk',
                    content: 'Heat Pump in a Public Utility Building',
                    textSubTitle: 'Eco-friendly and cost-effective solution for a public building',
                    description: `
                        In this project, we installed an air-to-water heat pump that efficiently heats and cools a public utility building. 
                        The system is environmentally friendly and provides significant energy savings, reducing building maintenance costs.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g5/14.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '4 weeks',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdynia',
                    content: 'Heat Pump in a Hotel',
                    textSubTitle: 'Energy-efficient heating and cooling for a hotel',
                    description: `
                        We installed an air-to-water heat pump system that allows for independent temperature control in each hotel room. 
                        This lets guests adjust the temperature to their preferences, while the hotel saves significantly on energy costs, 
                        optimizing operational expenses.
                    `,
                    images: [
                        `${commonTranslate.enUS.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.enUS.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '3 weeks',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `40 m<sup>2</sup>`}}/>,
                },

            ]
        },
        offer: {
            title: 'Offer',
            content: 'Meet our offer',
            url: '/#construction-elements',
            contactButton: commonTranslate.enUS.action.contactButton,
            actions: {
                title: 'Order the installation of selected components',
                description: 'Explore our installation offer and choose what interests you the most',
                buttons: [
                    {
                        name: `Solar panels`,
                        icon: <SolarPowerIcon/>,
                        image: `/assets/solar_panel.png`,
                    },
                    {
                        name: `Building insulation`,
                        icon: <AcUnitIcon/>,
                        image: `/assets/heat_pump.png`,
                    },
                    {
                        name: `Heat pump`,
                        icon: <HeatPumpIcon/>,
                        image: `/assets/heat_pump.png`,
                    },
                    {
                        name: `Underfloor heating`,
                        icon: <CabinIcon/>,
                        image: `/assets/floor_heating.png`,
                    }
                ],
                content: {
                    title: 'Overview cost analysis for heat pump',
                    data: [
                        {
                            title: 'Completion time',
                            icon: <QueryBuilderIcon fontSize={"inherit"}/>,
                            description: '2 weeks'
                        },
                        {
                            title: 'Surface area',
                            icon: <HomeIcon fontSize={"inherit"}/>,
                            description: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>
                        },
                        {
                            title: 'Heating period',
                            icon: <CalendarMonthIcon fontSize={"inherit"}/>,
                            description: '1 month',
                        }
                    ],
                    table: {
                        tableHeader: [
                            "Fuel type",
                            "Usable area",
                            "Value (unit)",
                            "Price (PLN)"
                        ],
                        fuelVariants: [
                            {
                                icon: <HeatPumpIcon/>,
                                fuelName: "Heat pump",
                                surfaceArea: 50,
                                value: "value",
                                price: 41
                            },
                            {
                                icon: <ForestIcon/>,
                                fuelName: "Wood",
                                surfaceArea: 50,
                                value: "1 t",
                                price: 450
                            },
                            {
                                icon: <OutdoorGrillIcon/>,
                                fuelName: "Coal",
                                surfaceArea: 50,
                                value: "150 kg",
                                price: 600
                            },
                            {
                                icon: <PropaneTankIcon/>,
                                fuelName: "Gas",
                                surfaceArea: 50,
                                value: "70 kWh/h",
                                price: 170
                            },
                            {
                                icon: <FlashOnIcon/>,
                                fuelName: "Electricity",
                                surfaceArea: 50,
                                value: "120 kWh",
                                price: 210
                            }
                        ]
                    }
                },
                summary: `Below are the calculations for a single-family house with the area specified in the parameters.
                          To learn more about insulating your own home, contact us.`

            }
        },
        footer: {
            products: {
                title: 'Products',
                solarPanel: {
                    title: 'Solar Panels',
                    url: '/#construction-elements',
                },
                buildingInsulation: {
                    title: 'Building Insulation',
                    url: '/#construction-elements',
                },
                heatPump: {
                    title: 'Heat pump',
                    url: '/#construction-elements',
                },
                floorHeating: {
                    title: 'Floor heating',
                    url: '/#construction-elements',
                },
            },
            information: {
                title: 'Information',
                privacyPolicy: {
                    title: 'Privacy policy',
                    url: '#',
                },
                license: {
                    title: 'License',
                    url: '#',
                },
                faq: {
                    title: 'FAQ',
                    url: '#',
                },
            },
            about: {
                title: 'About',
            },
            subscribe: {
                label: 'Subscribe',
            },
            address: {
                label: 'ul. Rynek 1, 00-000, Wrocław - Beaver Plumbers -',
            },
            termsAndConditions: {
                label: 'Terms & Conditions'
            },
            privacyPolicy: {
                label: 'Privacy Policy'
            },
            sitemap: {
                label: 'Sitemap'
            },
            designedBy: {
                label: 'Designed by Maksim Halushka & Mateusz Szyprowski'
            },
            contactUs: {
                label: 'Contact Us'
            }
        },
        banner: {
            info: 'Site is under construction. Please contact us:',
            phone: commonTranslate.enUS.phone,
        }
    },
    plPL: {
        label: 'PL',
        company: {
            title: commonTranslate.plPL.company,
            email: commonTranslate.plPL.email,
            phone: commonTranslate.plPL.phone,
        },
        home: {
            title: "Główna",
            url: "/#home",
            readMore: "Czytaj więcej",
            company: {
                shortDescription: commonTranslate.plPL.company + ' profesjonalne wykończenie i  systemy ogrzewania',
                extendedDescription: `
                    Specjalizujemy się w systemach ogrzewania oraz wykończeniach. 
                    Zajmujemy się instalacją pomp ciepła i ogrzewania podłogowego. 
                    Docieplamy budynki i pomagamy w remontach
                `,
                contactButton: commonTranslate.plPL.action.contactButton,
            },
            header: {
                company: commonTranslate.plPL.company,
                pageMap: 'Mapa strony',
                email: commonTranslate.plPL.email,
            },
            navigation: {
                offer: 'Oferta',
                projects: 'Realizacje',
                contact: 'Kontakt',
            },
            calculator: {
                currency: commonTranslate.plPL.currency,
                summary: {
                    title: 'Kalkulator oszczędności ciepła:',
                    result: 'Koszty utrzymania:',
                },
                actions: {
                    title: 'Wybierz interesujące opcje na rysunku:',
                    solarPanel: {
                        title: 'Panele fotowoltaiczne',
                        description: "Panele fotowoltaiczne pomogą zasilić pompę",
                    },
                    heatPump: {
                        title: 'Pompa ciepła',
                        description: "Zadbaj o ciepło budynku poprzez dodatkową izolację cieplną",
                    },
                    floorHeating: {
                        title: 'Ogrzewanie podłogowe',
                        description: "Idealne połączenie z panelami fotowoltaicznymi i pompą ciepła",
                    },
                    buildingInsulation: {
                        title: 'Dodatkowe ocieplenie ścian',
                        description: "Najważniejszy element układanki, pozwoli na maksimum oszczędności",
                    }
                },
            },
            carouselContent: [
                {
                    title: 'Panele fotowoltaiczne',
                    contentShort: `
                        Panele fotowoltaiczne zamieniają energię słoneczną w prąd przez ogniwa fotowoltaiczne, 
                        konwertując go na prąd zmienny za pomocą inwertera.
                    `,
                    content: `
                        Panele fotowoltaiczne, zwane modułami PV, są kluczowym elementem w generowaniu energii elektrycznej z promieniowania słonecznego. 
                        Składają się z wielu ogniw fotowoltaicznych zamontowanych w ramie, przetwarzają światło słoneczne w 
                        energię elektryczną poprzez efekt fotowoltaiczny. Ogniwa te generują prąd stały z fotonów światła słonecznego, 
                        który jest konwertowany na prąd zmienny przez inwerter. Panele są wydajne, trwałe i łatwe w instalacji, znajdują zastosowanie w 
                        domach i elektrowniach. Korzyści to m.in. redukcja emisji CO2, oszczędność na rachunkach i niezależność energetyczna. 
                        Montaż przyczynia się także do wzrostu wartości nieruchomości i propagowania zrównoważonego stylu życia. 
                        Rosnąca zaawansowanie i dostępność technologii fotowoltaicznych kierują ku bardziej zrównoważonej przyszłości energetycznej.
                    `,
                },
                {
                    title: 'Ocieplenie budynku',
                    contentShort: `
                        Ocieplenie budynku to klucz dla efektywności energetycznej i komfortu termicznego, 
                        redukując utratę ciepła, koszty energii oraz utrzymując przyjemną temperaturę.
                    `,
                    content: `
                        Ocieplenie budynku ma kluczowe znaczenie dla efektywności energetycznej i komfortu termicznego. 
                        Poprawiając izolację termiczną ścian, dachu i fundamentów, możliwe jest znaczne zmniejszenie utraty ciepła i zapewnienie stabilnej temperatury we wnętrzu. 
                        Działania te przyczyniają się do efektywnej redukcji kosztów związanych z zużyciem energii, 
                        co ma istotny wpływ na budżet domowy. Dodatkowo, dobrze ocieplony budynek pozwala na utrzymanie odpowiedniego poziomu ciepła 
                        bez nadmiernego zużycia ogrzewania, co jest korzystne zarówno dla środowiska, jak i wygody mieszkańców.
                    `,
                },
                {
                    title: 'Pompa ciepła',
                    contentShort: `
                        Pompa ciepła to nowoczesne źródło ciepła dla budynków i wody, efektywna i ekologiczna, 
                        obniżająca koszty ogrzewania i emisję gazów, idealna dla nowych i istniejących budynków.
                    `,
                    content: `
                        Pompa ciepła to nowoczesne rozwiązanie grzewcze, wykorzystujące energię z otoczenia do produkcji ciepła. 
                        Działa na zasadzie przekształcania niskiej temperatury otoczenia na wyższą temperaturę, 
                        która może być używana do ogrzewania budynków oraz wody użytkowej. Pompy ciepła są efektywne i ekologiczne, 
                        ponieważ wymagają niewielkiej ilości energii elektrycznej do działania. Mogą być wykorzystywane zarówno w nowo budowanych, 
                        jak i istniejących budynkach, przyczyniając się do obniżenia kosztów ogrzewania i emisji gazów cieplarnianych. 
                    `,
                },
                {
                    title: 'Ogrzewanie podłogowe',
                    contentShort: `
                        Ogrzewanie podłogowe to innowacyjna metoda z wodnym krążeniem w podłodze, 
                        gwarantująca równomierne ogrzewanie i efektywność. Idealne jako główne źródło ciepła lub uzupełnienie grzejników.
                    `,
                    content: `
                        Ogrzewanie podłogowe to innowacyjna metoda zapewnienia komfortu termicznego w pomieszczeniach. 
                        Działa poprzez przewodnictwo cieplne, gdzie woda grzejna krąży w specjalnych rurach w podłodze. 
                        To niskotemperaturowe rozwiązanie ma szereg zalet, m.in. równomierne rozprowadzenie ciepła, 
                        brak widocznych elementów grzewczych oraz oszczędność energii dzięki niższym temperaturom pracy. 
                        Ogrzewanie podłogowe może być głównym źródłem ciepła lub uzupełnieniem dla tradycyjnych grzejników, 
                        tworząc efekt ciepłej podłogi, który jest szczególnie przyjemny w chłodniejsze dni.
                    `,
                },
            ]
        },
        contact: {
            label: 'Kontakt',
            url: '/#contact',
            shortInfo: 'Beaver Plumbers',
            info: `
            Przykładowy adres firmy
            ul. Rynek 1, 00-000, Wrocław
            Tel. +48 000 000 000
            NIP: 0000000000
            `,
            form: {
                name: {
                    label: 'Imię',
                    placeholder: 'Imię'
                },
                email: {
                    label: 'Adres Email',
                    placeholder: 'Adres Email'
                },
                phone: {
                    label: 'Numer telefonu',
                    placeholder: 'Numer telefonu'
                },
                message: {
                    label: 'Treść wiadomości',
                    placeholder: 'Treść wiadomości',
                    placeholderMessage: 'Proszę opisać treść zgłoszenia...'
                },
                city: {
                    label: 'Miasto',
                    placeholder: 'Miasto'
                },
                street: {
                    label: 'Ulica',
                    placeholder: 'Ulica'
                },
                postalCode: {
                    label: 'Kod pocztowy',
                    placeholder: 'Kod pocztowy'
                },
                house: {
                    label: 'Numer domu',
                    placeholder: 'Numer domu'
                },
                houseNumber: {
                    label: 'Numer mieszkania',
                    placeholder: 'Numer mieszkania'
                },
                sendButton: {
                    label: 'Wyślij',
                    placeholder: 'Wyślij'
                },
                orderButton: commonTranslate.plPL.action.contactButton,
            },
            action: {
                email: {
                    success: 'Email wysłany pomyślnie',
                    error: 'Błąd podczas wysyłania emaila: '
                },
                order: {
                    success: 'Zamówienie utworzone pomyślnie',
                }
            }
        },
        about: {
            title: 'O Nas',
            url: '/#about',
            content: `
            Beaver Plumbers to polska firma, która obsługuje klientów i pracowników w budownictwie, projektach budowlanych i czystej energii.
            Zbudowaliśmy lokalne profesjonalne zespoły, a nasza działalność obejmuje całą Polskę.
            `,
            learnMore: 'Dowiedz się więcej',
            cardContent: [
                {
                    title: 'Energia odnawialna',
                    content: `
                    Energia odnawialna to energia pozyskiwana ze źródeł odnawialnych,
                    które są naturalnie uzupełniane w ludzkiej skali czasowej, w tym źródła neutralne pod względem emisji dwutlenku węgla, takie jak światło słoneczne, wiatr, deszcz, pływy,
                    fale i ciepło geotermalne. Termin ten często obejmuje również biomasę.
                    `,
                    img: about_1,
                },
                {
                    title: 'Panele Słoneczne',
                    content: `
                    Termin panel słoneczny jest używany potocznie dla modułu fotowoltaicznego (PV).
                    Moduł PV to zespół ogniw fotowoltaicznych zamontowanych w ramie do instalacji.
                    Ogniwa fotowoltaiczne wykorzystują światło słoneczne jako źródło energii i wytwarzają prąd stały.
                    `,
                    img: about_2,
                },
                {
                    title: 'Projekty domów',
                    content: `
                    Plan domu to zestaw rysunków konstrukcyjnych lub roboczych (czasami nazywanych planami)
                    które określają wszystkie specyfikacje konstrukcyjne domu mieszkalnego, takie jak wymiary,
                    materiały, układy, metody i techniki instalacji. Projekty domów to jedna z naszych specjalizacji.
                    `,
                    img: about_3
                },
                {
                    title: 'Elektryczność',
                    content: `
                    Elektryczność to zespół zjawisk fizycznych związanych z obecnością i ruchem materii, która ma właściwość ładunku elektrycznego.
                    Elektryczność jest powiązana z magnetyzmem, który jest częścią zjawiska elektromagnetyzmu, opisanego równaniami Maxwella.
                    `,
                    img: about_4
                },
                {
                    title: 'Wykończenia',
                    content: `
                    Zbudowaliśmy relacje z najlepszymi, lokalnymi kontrahentami w Twojej okolicy.
                    Są w 100% licencjonowane i ubezpieczone — i będą zapewniać aktualizacje kamieni milowych podczas całej kompilacji.
                    `,
                    img: about_5
                },
                {
                    title: 'Pompa ciepła',
                    content: `
                    Doradzamy w wyborze najbardziej optymalnego źródła ciepła w Twoim domu lub firmie.
                    Czy to za pomocą folii grzewczych, które utrzymują ciepło podłogi, czy promienników podczerwieni, które są najłatwiejsze w montażu.
                    `,
                    img: about_6
                },
            ]
        },
        service: {
            title: 'Serwis',
            url: '/#service',
            content: `
            Poznaj naszą ofertę usług. Możesz łatwo wybrać czas, który odpowiada Twoim osobistym wymaganiom
            `,
            submit: 'Zatwierdź',
            timeLine: [
                {
                    title: 'Wybierz datę',
                    content: 'Wybierz odpowiedni dla siebie termin usługi',
                    icon: 'calendar',
                },
                {
                    title: 'Opisz zaistniały problem',
                    content: 'Nazwij zdarzenie serwisowe i podaj dane kontaktowe',
                    icon: 'create',
                },
                {
                    title: 'Zaakceptuj zasady',
                    content: 'Prześlij zgłoszenie serwisowe i zaakceptuj nasze zasady',
                    icon: 'check',
                },
                {
                    title: 'Zaczekaj na kontakt',
                    content: 'Weź kawę i poczekaj, aż się z Tobą skontaktujemy',
                    icon: 'call',
                },
            ]
        },
        gallery: {
            title: 'Galeria',
            url: '/#gallery',
            header: 'Poznaj nasze realizacje',
            headerDescription: `Zapoznaj się z najnowszymi realizacjami wykonanymi przez nas na terenie całej Polski. 
                                Każda z nich stanowi oddzielnie i starannie wykonaną inwstycje.`,
            deliveryTime: 'Czas realizacji:',
            workArea: 'Powierzchnia:',
            galleryContent: [
                {
                    title: 'Warszawa',
                    content: 'Instalacja pompy ciepła',
                    textSubTitle: 'Efektywne ogrzewanie i chłodzenie domu jednorodzinnego',
                    description: `
                        W tym projekcie zainstalowaliśmy nowoczesną pompę ciepła powietrze-woda, 
                        która odpowiada zarówno za ogrzewanie, jak i chłodzenie domu jednorodzinnego. 
                        System został zintegrowany z istniejącą instalacją grzewczą, co zapewniło klientowi 
                        komfort termiczny przez cały rok, przy jednoczesnej oszczędności energii.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g1/0.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/2.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g1/3.jpg`,
                    ],
                    deliveryTime: '2 tygodnie',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `25 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Wrocław',
                    content: 'Ogrzewanie podłogowe w biurze',
                    textSubTitle: 'Zrównoważone ogrzewanie biura z pompą ciepła',
                    description: `
                        Wykonaliśmy instalację ogrzewania podłogowego z wykorzystaniem pompy ciepła powietrze-woda. 
                        System zapewnia stabilne ogrzewanie w sezonie zimowym oraz efektywne chłodzenie w lecie. 
                        Dzięki temu w biurze panuje komfortowa temperatura przez cały rok, co przyczyniło się 
                        do zwiększenia efektywności pracy zespołu.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g2/1.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g2/2.jpg`,
                    ],
                    deliveryTime: '2 tygodnie',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Szczecin',
                    content: 'System grzewczy w hotelu',
                    textSubTitle: 'Indywidualne sterowanie temperaturą w każdym pokoju',
                    description: `
                        Zainstalowaliśmy pompę ciepła powietrze-woda, która umożliwia niezależne sterowanie 
                        temperaturą w każdym pokoju hotelowym. Dzięki temu goście mają pełną kontrolę nad komfortem, 
                        a hotel oszczędza energię, co znacząco obniżyło koszty eksploatacji.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g3/0.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g3/1.jpg`,
                    ],
                    deliveryTime: '1 miesiąc',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `30 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Wałbrzych',
                    content: 'Ogrzewanie podłogowe w hali produkcyjnej',
                    textSubTitle: 'Stała temperatura w hali produkcyjnej przy minimalnym zużyciu energii',
                    description: `
                        Zainstalowaliśmy system ogrzewania podłogowego w hali produkcyjnej, zasilany pompą ciepła powietrze-woda. 
                        System utrzymuje stałą, komfortową temperaturę, niezależnie od warunków zewnętrznych, 
                        co wpływa na komfort pracy pracowników oraz optymalizację kosztów energii.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g4/11.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g4/12.jpg`,
                    ],
                    deliveryTime: '1 miesiąc',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `70 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdańsk',
                    content: 'Pompa ciepła w budynku użyteczności publicznej',
                    textSubTitle: 'Ekologiczne i oszczędne rozwiązanie dla budynku publicznego',
                    description: `
                        W tym projekcie zainstalowaliśmy pompę ciepła powietrze-woda, która efektywnie ogrzewa i chłodzi budynek użyteczności publicznej. 
                        System jest przyjazny dla środowiska i zapewnia znaczące oszczędności energetyczne, redukując koszty utrzymania budynku.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g5/14.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '4 tygodnie',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>,
                },
                {
                    title: 'Gdynia',
                    content: 'Pompa ciepła w hotelu',
                    textSubTitle: 'Energooszczędne ogrzewanie i chłodzenie hotelu',
                    description: `
                        Zainstalowaliśmy system pompy ciepła powietrze-woda, który zapewnia niezależne sterowanie temperaturą w każdym pokoju hotelowym. 
                        Dzięki temu goście mogą dostosować temperaturę do swoich preferencji, a hotel znacznie oszczędza na kosztach energii, 
                        co przekłada się na optymalizację wydatków operacyjnych.
                    `,
                    images: [
                        `${commonTranslate.plPL.gallery.imagesPath}g5/15.jpg`,
                        `${commonTranslate.plPL.gallery.imagesPath}g5/16.jpg`,
                    ],
                    deliveryTime: '3 tygodnie',
                    workAreaSize: <span dangerouslySetInnerHTML={{__html: `40 m<sup>2</sup>`}}/>,
                },

            ]
        },
        offer: {
            title: 'Oferta',
            content: 'Poznaj naszą ofertę',
            url: '/#construction-elements',
            contactButton: commonTranslate.plPL.action.contactButton,
            actions: {
                title: 'Zamów instalacje wybranych komponentów',
                description: 'Zapoznaj się z naszą ofertą instalacji i wybierz to co Cię najbardziej interesuje',
                buttons: [
                    {
                        name: `Panele słoneczne`,
                        icon: <SolarPowerIcon/>,
                        image: `/assets/solar_panel.png`,
                    },
                    {
                        name: `Ocieplenie budynku`,
                        icon: <AcUnitIcon/>,
                        image: `/assets/heat_pump.png`,
                    },
                    {
                        name: `Pompa ciepła`,
                        icon: <HeatPumpIcon/>,
                        image: `/assets/heat_pump.png`,
                    },
                    {
                        name: `Ogrzewanie podłogowe`,
                        icon: <CabinIcon/>,
                        image: `/assets/floor_heating.png`,
                    }
                ],
                content: {
                    title: 'Poglądowa analiza kosztów dla pompy ciepła',
                    data: [
                        {
                            title: 'Czas realizacji',
                            icon: <QueryBuilderIcon fontSize={"inherit"}/>,
                            description: '2 tygodnie'
                        },
                        {
                            title: 'Powierzchnia',
                            icon: <HomeIcon fontSize={"inherit"}/>,
                            description: <span dangerouslySetInnerHTML={{__html: `50 m<sup>2</sup>`}}/>
                        },
                        {
                            title: 'Okres grzewczy',
                            icon: <CalendarMonthIcon fontSize={"inherit"}/>,
                            description: '1 miesiąc',
                        }
                    ],
                    table: {
                        tableHeader: [
                            "Rodzaj paliwa",
                            "Powierzchnia użytkowa",
                            "Wartość (jednostka)",
                            "Cena (zł)"
                        ],
                        fuelVariants: [
                            {
                                icon: <HeatPumpIcon/>,
                                fuelName: "Pompa ciepła",
                                surfaceArea: 50,
                                value: "wartość",
                                price: 41
                            },
                            {
                                icon: <ForestIcon/>,
                                fuelName: "Drewno",
                                surfaceArea: 50,
                                value: "1 t",
                                price: 450
                            },
                            {
                                icon: <OutdoorGrillIcon/>,
                                fuelName: "Węgiel",
                                surfaceArea: 50,
                                value: "150 kg",
                                price: 600
                            },
                            {
                                icon: <PropaneTankIcon/>,
                                fuelName: "Gaz",
                                surfaceArea: 50,
                                value: "70 kWh/h",
                                price: 170
                            },
                            {
                                icon: <FlashOnIcon/>,
                                fuelName: "Prąd",
                                surfaceArea: 50,
                                value: "120 kWh",
                                price: 210
                            }
                        ]
                    }
                },
                summary: `Poniżej przedstawione są wyliczenia dla domu jednorodzinnego o powierzchni zadanej w parametrach. 
                          Aby dowiedzieć się więcej o ociepleniu własnego domu skontaktuj się z nami.`
            },
        },
        footer: {
            products: {
                title: 'Produkty',
                solarPanel: {
                    title: 'Panele słoneczne',
                    //TODO: make it generic, it appears in few places
                    url: '/#construction-elements',
                },
                buildingInsulation: {
                    title: 'Ocieplenie budynku',
                    url: '/#construction-elements',
                },
                heatPump: {
                    title: 'Pompy ciepła',
                    url: '/#construction-elements',
                },
                floorHeating: {
                    title: 'Ogrzewanie podłogowe',
                    url: '/#construction-elements',
                },
            },
            information: {
                title: 'Informacja',
                privacyPolicy: {
                    title: 'Polityka prywatności',
                    url: '#',
                },
                license: {
                    title: 'Umowy i licencje',
                    url: '#',
                },
                faq: {
                    title: 'FAQ',
                    url: '#',
                },
            },
            about: {
                title: 'O nas',
            },
            subscribe: {
                label: 'Subskrypcja',
            },
            address: {
                label: 'ul. Rynek 1, 00-000, Wrocław - Beaver Plumbers',
            },
            termsAndConditions: {
                label: 'Zasady & Warunki'
            },
            privacyPolicy: {
                label: 'Polityka prywatności'
            },
            sitemap: {
                label: 'Mapa strony'
            },
            designedBy: {
                label: 'Opracowano przez Maksim Halushka dla Beaver Plumbers'
            },
            contactUs: {
                label: 'Skontaktuj się z nami'
            }
        },
        banner: {
            info: 'Strona jest w procesie tworzenia. Prosimy o kontakt:',
            phone: commonTranslate.plPL.phone,
        }
    }
}

export default translate;
