import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import contact from "../../../asset/contact.png";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, {Box} from "@mui/material";
import {classes} from "./contact-info-styles";
import {classes as classesApp} from "../../app-styles";

const StyledContactInfo = styled(Box)(({theme}) => ({
    [`& .${classes.imgSize}`]: {
        maxWidth: '20vh',
        [theme.breakpoints.down('md')]: {
            maxWidth: '20vw',
        },
    },
    [`& .${classes.infoWrapper}`]: {
        textAlign: "center",
        marginBottom: "30px", // Increased space between elements
    },
    [`& .${classesApp.gridText}`]: {
        textAlign: "center",
        color: theme.palette.primary.background,
    },
    [`& .${classes.title}`]: {
        color: theme.palette.primary.main,
        textAlign: "center",
        fontSize: "0.875rem",
        textTransform: "uppercase",
        fontWeight: "bold",
        marginBottom: "10px", // Adjusted margin for better spacing
    },
    [`& .${classes.subtitle}`]: {
        textAlign: "center",
        color: theme.palette.primary.background,
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: "15px", // Adjusted margin for better spacing
    },
    [`& .${classes.body}`]: {
        color: theme.palette.primary.background,
        fontSize: "0.875rem",
        lineHeight: "1.6",
    },
    [`& .${classes.separator}`]: {
        width: "30px",
        height: "2px",
        backgroundColor: theme.palette.primary.background,
        borderRadius: "50px",
        margin: "25px auto",
    },
}));

const ContactInfo = (props) => {
    return (
        <StyledContactInfo>
            <Grid item className={classes.infoWrapper}>
                <img src={contact} alt="" className={classes.imgSize}/>
            </Grid>
            <Grid item>
                <CardContent className={classesApp.gridText}>
                    <Typography variant="h6" display="block" className={classes.title}>
                        {props.label}
                    </Typography>
                    <Typography variant="body1" component="h2" className={classes.subtitle}>
                        {props.shortInfo}
                    </Typography>
                    <Box className={classes.separator}/>
                    <Typography variant="body1" className={classes.body}>
                        {props.info}
                    </Typography>
                </CardContent>
            </Grid>
        </StyledContactInfo>
    );
};

export default ContactInfo;
