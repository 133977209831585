import * as React from "react";
import {useState} from "react";
import {Fab, Grid, Link, Paper, Typography, useMediaQuery} from "@mui/material";
import {classes} from "./order-type-block-styles";
import {styled} from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useNavigate} from "react-router-dom";
import {ServiceOrderContext} from "../../../context/service-order-context/service-order-context";

const StyledOrderTypeBlock = styled(Paper)(({theme, image}) => ({
    height: "90%",
    borderColor: "blue",
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    textAlign: "center",
    lineHeight: "20px",

    backgroundImage: `url("${image}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    [`& .${classes.content}`]: {
        width: "100%",
        paddingRight: "8vw",
        paddingLeft: "8vw",
    },
}));

const OrderTypeBlock = (props) => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const [order] = useState(props);
    const navigate = useNavigate();
    const {serviceOrderData, setServiceOrderType, setServiceOrderSubtype} = React.useContext(ServiceOrderContext);

    const navigateToNextComponent = () => {
        const serviceOrderTypeOrSubtype = {
            id: order.id,
            name: order.name,
            translationKey: order.translationKey,
            image: order.image,
        };
        if (order.typeIdentification === "TYPE") {
            console.log("Setting type");
            setServiceOrderType(serviceOrderTypeOrSubtype);
        } else if (order.typeIdentification === "SUBTYPE") {
            console.log("Setting subtype");
            setServiceOrderSubtype(serviceOrderTypeOrSubtype);
        }
        console.log("Service Order Data -> ", serviceOrderData);
        navigate(order.actionUrl);
    };

    return (
        <StyledOrderTypeBlock image={order.image}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{height: "100%"}}
            >
                <Grid
                    item
                    container
                    direction="column"
                    xs={9}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                    height={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.6)",
                    }}
                >
                    <Typography variant={"h6"}>{order.name}</Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <Fab
                        component={Link}
                        onClick={navigateToNextComponent}
                        color="primary"
                        aria-label="edit"
                        size={isSmallScreen ? "medium" : "large"}
                    >
                        <ArrowForwardIcon/>
                    </Fab>
                </Grid>
            </Grid>
        </StyledOrderTypeBlock>
    );
};

export default OrderTypeBlock;
