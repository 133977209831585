const PREFIX = 'construction-elements-content-description';

export const classes = {
    descriptionAttributeText: `${PREFIX}-text-specification`,
    descriptionAttributeWrapper: `${PREFIX}-description-attribute-wrapper`,
    descriptionAttributeElement: `${PREFIX}-description-attribute-element`,
    descriptionTextWrapper: `${PREFIX}-description-text-wrapper`,
    descriptionTextContent: `${PREFIX}-description-text-content`,
    contentTitle: `${PREFIX}-content-title`
};
