const PREFIX = 'footer';

export const classes = {
    legalLink: `${PREFIX}-legal-link`,
    rightMenu: `${PREFIX}-right-menu`,
    middleMenu: `${PREFIX}-middle-menu`,
    bottomPanelContainer: `${PREFIX}-bottom-panel-container`,
    bottomPanelContainerLinks: `${PREFIX}-bottom-panel-container-links`,
    bottomPanelContainerCreatedBy: `${PREFIX}-bottom-panel-container-created-by`,
};
