import type {ServiceOrderData} from "./data/service-order-data";

const reduce = (state: ServiceOrderData, action) => {
    switch (action.type) {
        case "UPDATE_SERVICE_ORDER_DATA_SERVICE_ORDER_DETAILS":
            return {
                ...state,
                serviceOrderDetails: action.payload
            }
        case "UPDATE_SERVICE_ORDER_DATA_SERVICE_ORDER_TYPE":
            return {
                ...state,
                serviceOrderType: action.payload
            }
        case "UPDATE_SERVICE_ORDER_DATA_SERVICE_ORDER_SUBTYPE":
            return {
                ...state,
                serviceOrderSubtype: action.payload
            }
        default:
            return state;
    }
}

export default reduce;
