import {ApiContext} from "./api-context";
import axios from "axios";

const api = axios.create({
    baseURL: `${process.env.REACT_APP_RESOURCE_URI}api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const ApiProvider = ({children}) => {
    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
};
