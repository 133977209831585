import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {keyframes} from "@emotion/react";
import {Box, Container, Typography} from "@mui/material";
import {classes} from "./banner-styles";
import ConstructionIcon from "@mui/icons-material/Construction";
import CancelIcon from "@mui/icons-material/Cancel";
import translate from "../../language/translate";
import {LocaleContext} from "../../context/locale-context/locale-context";
import {defaultLocale} from "../../context/locale-context/default-locale";

const shake = keyframes`
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
`

const StyledBanner = styled.article(({theme}) => ({
    position: "relative",
    height: "100vh",
    backgroundColor: theme.palette.secondary.main + "AA",
    component: "article",

    [`& .${classes.contentWrapper}`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100vw",
        minHeight: "100vh",
        color: theme.palette.secondary.main,
    },
    [`& .${classes.bannerWrapper}`]: {
        backgroundColor: theme.palette.secondary.background,
        borderRadius: 20
    },
    [`& .${classes.imageWrapper}`]: {
        maxWidth: "30%",
        borderRadius: 100,
        backgroundColor: theme.palette.secondary.main,
        padding: 20,
        display: "flex",
        justifyContent: "center",
    },
    [`& .${classes.cancelIcon}`]: {
        padding: 20,
        fontSize: "2rem"
    },
    [`& .${classes.icon}`]: {
        width: 50,
        height: 50,
        fontSize: "inherit",
        color: theme.palette.secondary.main,
    },
    [`& .${classes.iconShakeWrapper}`]: {
        animation: `${shake} 0.5s`,
    },
    [`& .${classes.contactWrapper}`]: {
        display: "flex",
        padding: 20,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    [`& .${classes.imageInfoWrapper}`]: {
        display: "flex",
        justifyContent: "center",
    },
}));

const Banner = (props) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translationBanner = translation.banner;
    const [shake, setShake] = useState(classes.iconShakeWrapper);

    useEffect(() => {
        setTimeout(() => {
            setShake(shake === undefined ? classes.iconShakeWrapper : undefined);
            console.log("New value");
        }, 1000)
    }, [shake])

    return (
        <StyledBanner>
            <Container className={classes.contentWrapper}>
                <Box className={classes.bannerWrapper}>
                    <CancelIcon className={classes.cancelIcon} onClick={() => props.hideBanner(false)}/>
                    <Box className={classes.imageInfoWrapper}>
                        <Box className={classes.imageWrapper}>
                            <img alt={"logo"} style={{maxWidth: "40%"}} src={"/assets/logo_3_pr.png"}/>
                        </Box>
                    </Box>
                    <Box className={classes.contactWrapper}>
                        <ConstructionIcon className={`${shake} ${classes.icon}`}/>
                        <Typography>
                            {translationBanner.info}
                        </Typography>
                        <Typography>
                            {translationBanner.phone}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </StyledBanner>
    )
}

export default Banner;
