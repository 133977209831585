import type {AppData} from "./data/app-data";
import {updateSelectedElements} from "./util/app-context-update-selected-elements";

const reduce = (state: AppData, action) => {
    switch (action.type) {
        case "UPDATE_APP_DATA_SELECTED_CONSTRUCTION_ELEMENT":
            return {
                ...state,
                selectedConstructionElement: action.payload
            }
        case "UPDATE_APP_DATA_SELECTED_ELEMENTS":
            return {
                ...state,
                selectedElements: updateSelectedElements(state, action)
            }
        case "SET_INFO":
            return {
                ...state,
                info: action.payload
            }
        default:
            return state;
    }
}

export default reduce;

