import React, {useReducer} from "react";
import {ServiceOrderContext} from "./service-order-context";
import type {ServiceOrderDataConnector} from "./data/service-order-data-connector";
import reduce from "./service-order-context-reducer";
import type {ServiceOrderType} from "../../component/order-managment/shared/dto/service-order-type";
import type {ServiceOrderSubtype} from "../../component/order-managment/shared/dto/service-order-subtype";
import type {ServiceOrderData} from "./data/service-order-data";
import type {ServiceOrderDetails} from "../../component/order-managment/shared/dto/service-order-details";

const defaultValue: ServiceOrderDataConnector = {
    serviceOrderData: {
        serviceOrderDetails: null,
        serviceOrderType: null,
        serviceOrderSubtype: null,
    },
};

export const ServiceOrderContextProvider = ({children}) => {
    const [serviceOrderData: ServiceOrderData, setServiceOrderData] = useReducer(reduce, defaultValue.serviceOrderData);

    const setServiceOrderDetails = (serviceOrderDetails: ServiceOrderDetails) => {
        setServiceOrderData({
            type: "UPDATE_SERVICE_ORDER_DATA_SERVICE_ORDER_DETAILS",
            payload: serviceOrderDetails
        })
    };

    const setServiceOrderType = (serviceOrderType: ServiceOrderType) => {
        setServiceOrderData({
            type: "UPDATE_SERVICE_ORDER_DATA_SERVICE_ORDER_TYPE",
            payload: serviceOrderType,
        })
    };

    const setServiceOrderSubtype = (serviceOrderSubtype: ServiceOrderSubtype) => {
        setServiceOrderData({
            type: "UPDATE_SERVICE_ORDER_DATA_SERVICE_ORDER_SUBTYPE",
            payload: serviceOrderSubtype
        })
    };

    const value: ServiceOrderDataConnector = {
        serviceOrderData: serviceOrderData,
        setServiceOrderDetails,
        setServiceOrderType,
        setServiceOrderSubtype,
    };

    return <ServiceOrderContext.Provider value={value}>{children}</ServiceOrderContext.Provider>;
}
