const PREFIX = 'gallery';

export const classes = {
    headerWrapper: `${PREFIX}-header-wrapper`,
    headerTitle: `${PREFIX}-header-title`,
    headerContent: `${PREFIX}-header-content`,
    headerBackground: `${PREFIX}-header-background`,
    galleryContainerWrapper: `${PREFIX}-container-content-grid-wrapper`,
    galleryContainer: `${PREFIX}-container`,
    cardContent: `${PREFIX}-card-content`,
    cardContentInfo: `${PREFIX}-card-content-info`,
    cardContentActionArea: `${PREFIX}-card-content-action-area`,
    cardContentImage: `${PREFIX}-card-content-image`,
    text: `${PREFIX}-text`,
    galleryContentDescriptionWrapper: `${PREFIX}-gallery-content-description-wrapper`,
};
