import {AvailabilityCheck} from '../shared/dto/availability-check';
import {AvailabilityWithTimeSlot} from '../shared/dto/availability-with-time-slot';
import {useApi} from "../../../context/api-context/api-context";

export const useAppointmentApi = () => {
    const api = useApi();

    const fetchAvailableSlotsForDates = async (availabilityCheck: AvailabilityCheck): Promise<AvailabilityWithTimeSlot> => {
        console.log("Check availability for given dates", availabilityCheck);
        try {
            const response = await api.post('/availability/list', availabilityCheck);
            if (response.status === 200) {
                return response.data;
            } else {
                console.warn(`Unexpected response status: ${response.status}`);
                return [];
            }
        } catch (error) {
            console.error('Failed to fetch availability:', error);
            return [];
        }
    };

    return {fetchAvailableSlotsForDates};
};
