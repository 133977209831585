import React from "react";
import {styled} from '@mui/material/styles';
import {classes} from "./construction-elements-content-description-style"
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {defaultLocale} from "../../../context/locale-context/default-locale";

const StyledConstructionElementsContentDescription = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",

    [`& .${classes.descriptionAttributeWrapper}`]: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
        paddingTop: 10,
    },
    [`& .${classes.descriptionAttributeElement}`]: {
        paddingTop: 10,
        paddingRight: 40,
    },
    [`& .${classes.descriptionAttributeText}`]: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'normal',
        letterSpacing: "none",
        textTransform: "none",
        paddingTop: 10,
        paddingBottom: 10,
        [`& svg`]: {
            paddingRight: 10,
            fontSize: '1.5rem',
            color: theme.palette.primary.main,
        }
    },
    [`& .${classes.descriptionTextWrapper}`]: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    [`& .${classes.descriptionTextContent}`]: {
        whiteSpace: 'pre-line',
        lineHeight: '1.6rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontWeight: 'normal',
        },
    },
    [`& .${classes.contentTitle}`]: {
        color: theme.palette.primary.main,
    },
}));

function ConstructionElementsContentDescription() {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedOffer = translation.offer;

    return (
        <StyledConstructionElementsContentDescription>
            <Typography variant={"h6"} className={classes.contentTitle}>
                {translatedOffer.actions.content.title}
            </Typography>
            <Box className={classes.descriptionAttributeWrapper}>
                <Box className={classes.descriptionAttributeElement}>
                    <Typography variant={"h6"}>{translatedOffer.actions.content.data[0].title}</Typography>
                    <Typography variant={"h6"} className={classes.descriptionAttributeText}>
                        {translatedOffer.actions.content.data[0].icon}
                        {translatedOffer.actions.content.data[0].description}
                    </Typography>
                </Box>
                <Box className={classes.descriptionAttributeElement}>
                    <Typography variant={"h6"}>{translatedOffer.actions.content.data[1].title}</Typography>
                    <Typography variant={"h6"} className={classes.descriptionAttributeText}>
                        {translatedOffer.actions.content.data[1].icon}
                        {translatedOffer.actions.content.data[1].description}
                    </Typography>
                </Box>
                <Box className={classes.descriptionAttributeElement}>
                    <Typography variant={"h6"}>{translatedOffer.actions.content.data[2].title}</Typography>
                    <Typography variant={"h6"} className={classes.descriptionAttributeText}>
                        {translatedOffer.actions.content.data[2].icon}
                        {translatedOffer.actions.content.data[2].description}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.descriptionAttributeWrapper}>
                <Typography variant={"body1"} className={classes.descriptionTextContent}>
                    {translatedOffer.actions.summary}
                </Typography>
            </Box>
        </StyledConstructionElementsContentDescription>
    );
}

export default ConstructionElementsContentDescription;
