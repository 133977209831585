const PREFIX = 'banner';

export const classes = {
    contentWrapper: `${PREFIX}-content-wrapper`,
    bannerWrapper: `${PREFIX}-banner-wrapper`,
    imageWrapper: `${PREFIX}-image-wrapper`,
    contactWrapper: `${PREFIX}-contact-wrapper`,
    iconShakeWrapper: `${PREFIX}-icon-shake-wrapper`,
    icon: `${PREFIX}-icon`,
    cancelIcon: `${PREFIX}-cancel-icon`,
    imageInfoWrapper: `${PREFIX}-image-info-wrapper`
};
