import type {ServiceOrderTypeExtended} from '../shared/dto/service-order-type-extended';
import type {ServiceOrderSubtypeExtended} from '../shared/dto/service-order-subtype-extended';
import {useApi} from "../../../context/api-context/api-context";

export const useOrderApi = () => {
    const api = useApi();

    const fetchAllServiceOrderTypes = async (): Promise<ServiceOrderTypeExtended[]> => {
        try {
            const response = await api.get('/order/type/list');
            if (response.status === 200) {
                return response.data;
            } else {
                console.warn(`Unexpected response status: ${response.status}`);
                return [];
            }
        } catch (error) {
            console.error('Failed to fetch service order types:', error);
            return [];
        }
    };

    const fetchAllServiceOrderTypeSubtypes = async (type: string): Promise<ServiceOrderSubtypeExtended[]> => {
        try {
            const response = await api.get(`/order/type/${type}/subtype/list`);
            if (response.status === 200) {
                return response.data;
            } else {
                console.warn(`Unexpected response status: ${response.status}`);
                return [];
            }
        } catch (error) {
            console.error('Failed to fetch service order subtypes:', error);
            return [];
        }
    };

    return {fetchAllServiceOrderTypes, fetchAllServiceOrderTypeSubtypes};
};
