import React, {useContext, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {
    Box,
    Button,
    CircularProgress,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    useMediaQuery
} from '@mui/material';
import {AcUnit, Build, Construction, ElectricalServices, Menu, Plumbing, WbSunny} from '@mui/icons-material';
import {useOrderApi} from '../order-managment/api/order-api';
import {useNavigate} from 'react-router-dom';
import {ServiceOrderContext} from '../../context/service-order-context/service-order-context';
import {useAuth} from 'react-oidc-context';
import {classes} from './home-styles';
import type {OrderTypeBlockExtendedProps} from "../order-managment/order-type-block/order-type-block-props";
import type {ServiceOrderType} from "../order-managment/shared/dto/service-order-type";

const StyledHome = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.primary.background,
    margin: 0,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    [`& .${classes.header}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        width: '100%',
    },
    [`& .${classes.nav}`]: {
        display: 'flex',
        gap: '40px',
        height: '8vh',
        alignItems: 'center',
        padding: '0 8vw',
    },
    [`& .${classes.navLink}`]: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 'bold',
        transition: 'color 0.3s, background-color 0.3s',
        padding: theme.spacing(1),
        borderRadius: '4px',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.background,
        },
    },
    [`& .${classes.main}`]: {
        width: '100%',
        flexGrow: 1,
        boxSizing: 'border-box',
    },
    [`& .${classes.logoWrapper}`]: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
        padding: '0 8vw',
        [theme.breakpoints.up('md')]: {
            height: '50vh',
        },
    },
    [`& .${classes.logo}`]: {
        width: '100%',
        maxWidth: '200px',
        maxHeight: '200px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '400px',
            maxHeight: '400px',
        },
        height: 'auto',
    },
    [`& .${classes.services}`]: {
        flexGrow: 1,
        height: '30%',
        padding: theme.spacing(2),
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },
    [`& .${classes.serviceItem}`]: {
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: theme.palette.primary.background,
        borderRadius: '8px',
        padding: theme.spacing(2.5),
        border: `2px solid ${theme.palette.secondary.main}`,
        transition: 'transform 0.3s, color 0.3s, background-color 0.3s',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
        '&:hover': {
            transform: 'scale(1.05)',
            color: theme.palette.primary.background,
            backgroundColor: theme.palette.primary.mainShadowed,
            [`& .${classes.icon}`]: {
                color: theme.palette.primary.background,
            },
        },
        [theme.breakpoints.up('md')]: {
            height: '80px',
        },
    },
    [`& .${classes.icon}`]: {
        fontSize: '1.8em',
        color: theme.palette.secondary.main,
    },
    [`& .${classes.mobileMenuButton}`]: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(2),
        borderRadius: '8px',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.background,
        },
    },
}));

const navLinks = [
    {href: '/#construction-elements', label: 'Oferta'},
    {href: '/#gallery', label: 'Realizacje'},
    {href: '/#contact', label: 'Kontakt'},
];

const serviceIcon = {
    PLUMBING: <Plumbing className={classes.icon}/>,
    UNBLOCKING_PIPE: <Construction className={classes.icon}/>,
    SERVICE_ELECTRICAL: <ElectricalServices className={classes.icon}/>,
    PLUMBING_SERVICE: <Build className={classes.icon}/>,
    SOLAR_COLLECTOR: <WbSunny className={classes.icon}/>,
    HEAT_PUMP: <AcUnit className={classes.icon}/>,
};

const Home = () => {
    const {fetchAllServiceOrderTypes} = useOrderApi();
    const [serviceOrderTypes, setServiceOrderTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const {setServiceOrderType, setServiceOrderSubtype} = useContext(ServiceOrderContext);
    const auth = useAuth();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    useEffect(() => {
        setLoading(true);
        fetchAllServiceOrderTypes().then(result => {
            const updatedTypes = result.map(type => ({
                ...type,
                appointmentUrl: `/order/${encodeURIComponent(type.serviceOrderTypeDto.name)}/subtype/list`
            }));
            setServiceOrderTypes(updatedTypes);
            setLoading(false);
        });
    }, []);

    const toggleDrawer = (open) => () => {
        console.log("Toggle drawer")
        setDrawerOpen(open);
    };

    const navigateToNextComponent = (orderTypeBlockExtendedProps: OrderTypeBlockExtendedProps) => {
        const {id, name, translationKey, image, actionUrl, typeIdentification} = orderTypeBlockExtendedProps;
        const serviceOrderTypeOrSubtype: ServiceOrderType = {id, name, translationKey, image};
        if (typeIdentification === 'TYPE') {
            setServiceOrderType(serviceOrderTypeOrSubtype);
        } else {
            setServiceOrderSubtype(serviceOrderTypeOrSubtype);
        }
        navigate(actionUrl);
    };

    if (loading) {
        return (
            <Grid item xs={12}>
                <CircularProgress color="secondary"/>
            </Grid>
        );
    }

    return (
        <StyledHome id="home"
                    component="section"
        >

            <header className={classes.header}>

                {!isSmallScreen && (
                    <nav className={classes.nav}>
                        {navLinks.map((link, index) => (
                            <a key={index} href={link.href}>
                                <Typography variant="h6" component="span"
                                            className={classes.navLink}>{link.label}</Typography>
                            </a>
                        ))}
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={auth.signinRedirect}
                        >
                            Zaloguj się
                        </Button>
                    </nav>
                )}
                {isSmallScreen && (
                    <>
                        <IconButton
                            className={classes.mobileMenuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <Menu/>
                        </IconButton>
                    </>
                )}
            </header>
            {isSmallScreen && (
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                >
                    <List>
                        {navLinks.map((link, index) => (
                            <ListItem
                                button
                                component="a"
                                key={index}
                                href={link.href}
                                onClick={toggleDrawer(false)}
                            >
                                <ListItemText primary={link.label}/>
                            </ListItem>
                        ))}
                        <ListItem button onClick={() => {
                            auth.signinRedirect();
                            toggleDrawer(false)();
                        }}>
                            <ListItemText primary="Zaloguj się"/>
                        </ListItem>
                    </List>
                </Drawer>
            )}
            <main className={classes.main}>
                <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.logoWrapper}>
                        <img src="./logo.png" alt="Beaver Plumbers Logo" className={classes.logo}/>
                    </Grid>
                    <Grid container spacing={3} className={classes.services} justifyContent="space-around">
                        {serviceOrderTypes.map((serviceOrderType, index) => {
                            if (!serviceOrderType?.serviceOrderTypeDto) return null;

                            const {id, translationKey, name, image} = serviceOrderType.serviceOrderTypeDto;
                            const actionUrl = serviceOrderType.appointmentUrl;
                            const orderTypeBlockExtendedProps: OrderTypeBlockExtendedProps = {
                                id,
                                name,
                                translationKey,
                                image,
                                actionUrl,
                                typeIdentification: 'TYPE'
                            };

                            return (
                                <Grid key={index} item xs={12} sm={6} md={4}>
                                    <Paper
                                        className={classes.serviceItem}
                                        onClick={() => navigateToNextComponent(orderTypeBlockExtendedProps)}
                                    >
                                        {serviceIcon[name] || null}
                                        {serviceOrderType.translation}
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </main>
        </StyledHome>
    );
};

export default Home;
