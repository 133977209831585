import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, CircularProgress, Grid, Link} from "@mui/material";
import {classes} from "./order-styles";
import styled from "@emotion/styled";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from "@mui/material/Typography";
import {useOrderApi} from "../api/order-api";
import OrderTypeBlock from "../order-type-block/order-type-block";
import {useParams} from "react-router-dom";
import type {ServiceOrderSubtypeExtended} from "../shared/dto/service-order-subtype-extended";
import type {OrderTypeBlockExtendedProps} from "../order-type-block/order-type-block-props";

const StyledOrder = styled(Box)(({theme}) => ({
    display: 'flex',
    backgroundColor: '#F1F5F6',
    justifyContent: "center",
    minHeight: "100vh",

    [`& .${classes.content}`]: {
        width: "100%",
        paddingRight: "8vw",
        paddingLeft: "8vw",
    },
    [`& .${classes.offerElement}`]: {
        height: "90%",
        borderColor: "blue",
        border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        textAlign: 'center',
        lineHeight: '20px',

        backgroundImage: 'url("/assets/plumbing.jpg")',
        backgroundPosition: "center",
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
    },
}));

const Order = () => {
    const {type} = useParams();
    const {fetchAllServiceOrderTypeSubtypes} = useOrderApi();
    const [serviceOrderSubtypesExtended: ServiceOrderSubtypeExtended[], setServiceOrderSubtypesExtended] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        fetchAllServiceOrderTypeSubtypes(type).then(result => {
            const updatedSubtypes = result.map(subtype => ({
                ...subtype,
                appointmentUrl: `/order/${encodeURIComponent(type)}/subtype/${encodeURIComponent(subtype.serviceOrderSubtypeDto.name)}/appointment`
            }));
            setServiceOrderSubtypesExtended(updatedSubtypes);
            setLoading(false);
        });
    }, [type]);


    return (
        <StyledOrder>
            <Box className={`${classes.content}`}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        <Typography variant={"h6"}>Główna</Typography>
                    </Link>
                    <Link underline="hover" color="inherit" href="#">
                        <Typography variant={"h6"}>Zamówienie</Typography>
                    </Link>
                </Breadcrumbs>
                <Grid container justifyContent={"space-between"}>
                    <TypeBlockWrapper types={serviceOrderSubtypesExtended} loading={loading}/>
                </Grid>
            </Box>
        </StyledOrder>
    )
}

const TypeBlockWrapper = ({types, loading}) => {
    return (
        <>
            {loading ? (
                <Grid item xs={12}>
                    <CircularProgress color="secondary"/>
                </Grid>
            ) : types.length === 0 ? (
                <Grid item xs={12}>
                    <Typography variant="body1">No subtypes found.</Typography>
                </Grid>
            ) : (
                types.map((serviceOrderSubtype, index) => {
                    if (!serviceOrderSubtype || !serviceOrderSubtype.serviceOrderSubtypeDto) {
                        console.error('Invalid serviceOrderSubtype:', serviceOrderSubtype);
                        return null;
                    }

                    const orderTypeBlockExtendedProps: OrderTypeBlockExtendedProps = {
                        id: serviceOrderSubtype.serviceOrderSubtypeDto.id,
                        typeIdentification: "SUBTYPE",
                        translationKey: serviceOrderSubtype.serviceOrderSubtypeDto.translationKey,
                        name: serviceOrderSubtype.translation,
                        info: serviceOrderSubtype.serviceOrderSubtypeDto.name,
                        image: serviceOrderSubtype.serviceOrderSubtypeDto.image,
                        actionUrl: serviceOrderSubtype.appointmentUrl,
                    };

                    return (
                        <React.Fragment key={serviceOrderSubtype.serviceOrderSubtypeDto.name}>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} height={"256px"}>
                                <OrderTypeBlock {...orderTypeBlockExtendedProps}/>
                            </Grid>
                            {((index + 1) % 3 !== 0) && (
                                <Grid item xs={0} sm={1.5} md={1.5} lg={1.5} xl={1.5}/>
                            )}
                        </React.Fragment>
                    );
                })
            )}
        </>
    );
};

export default Order;
