import React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import translate from '../../language/translate'
import FooterMenuMiddleItem from "./footer-menu-middle-item/footer-menu-middle-item";
import FooterMenuRight from "./footer-menu-right/footer-menu-right";
import FooterMenuLeft from "./footer-menu-left/footer-menu-left";
import {classes} from "./footer-menu-styles"
import {classes as classesApp} from "../app-styles";
import {LocaleContext} from "../../context/locale-context/locale-context";
import {Link} from "@mui/material";
import {defaultLocale} from "../../context/locale-context/default-locale";

const StyledFooter = styled(Box)(({theme}) => ({

    [`& .${classes.legalLink}`]: {
        color: theme.palette.secondary.main,
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            paddingRight: 20,
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: 10,
        },
    },
    [`& .${classes.rightMenu}`]: {
        [theme.breakpoints.down('lg')]: {
            alignItems: "center"
        },
        marginLeft: 'auto',
    },
    [`& .${classes.middleMenu}`]: {
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
            alignItems: "center",
            direction: "column"
        },
        direction: "row",
    },
    [`& .${classes.bottomPanelContainer}`]: {
        display: "flex",
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    [`& .${classes.bottomPanelContainerLinks}`]: {
        display: "flex",
        flexDirection: 'row',
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
            alignItems: "center",
            flexDirection: 'column',
        },
    },
    [`& .${classes.bottomPanelContainerCreatedBy}`]: {
        color: theme.palette.secondary.main,
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            paddingBottom: 10,
            minWidth: '100%',
        },
    }
}));

export const FooterMenu = () => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const translationFooter = translation.footer;

    return (
        <StyledFooter width={'100%'}>
            <Box
                 pb={6}
                 pt={2}
                 className={classesApp.top}
            >
                <Grid container spacing={4}>
                    <Grid container={true}
                          id={"leftMenu"}
                          item
                          xs={12} sm={12} md={2} lg={3} xl={3}
                    >
                        <FooterMenuLeft infoText={translationFooter.address.label}/>
                    </Grid>
                    <Grid container={true}
                          item
                          xs={12} sm={12} md={8} lg={6} xl={6}
                          spacing={2}
                          className={classes.middleMenu}
                    >
                        <FooterMenuMiddleItem menuHeader={translationFooter.products.title}
                                              menuItems={[
                                                  translationFooter.products.solarPanel,
                                                  translationFooter.products.buildingInsulation,
                                                  translationFooter.products.heatPump,
                                                  translationFooter.products.floorHeating,
                                              ]}
                                              numberOfSections={3}
                        />
                        <FooterMenuMiddleItem menuHeader={translationFooter.information.title}
                                              menuItems={[
                                                  translationFooter.information.faq,
                                                  translationFooter.information.license,
                                                  translationFooter.information.privacyPolicy,
                                              ]}
                                              numberOfSections={3}
                        />
                        <FooterMenuMiddleItem menuHeader={translationFooter.about.title}
                                              menuItems={[
                                                  translation.home,
                                                  translation.gallery,
                                                  translation.offer,
                                              ]}
                                              numberOfSections={3}
                        />
                    </Grid>
                    <Grid container={true}
                          item
                          xs={12} sm={12} md={2} lg={3} xl={3}
                          className={classes.rightMenu}
                          direction="column"
                    >
                        <FooterMenuRight menuHeader={translationFooter.subscribe.label}/>
                    </Grid>
                </Grid>
            </Box>
            <Box py={3}
                 className={`${classesApp.bottom} ${classes.bottomPanelContainer}`}
            >
                <Box className={classes.bottomPanelContainerLinks}>
                    <Link href={"#"}>
                        <Typography variant={"h6"} className={classes.legalLink}>
                            {translationFooter.termsAndConditions.label}
                        </Typography>
                    </Link>
                    <Link href={"#"}>
                        <Typography variant={"h6"} className={classes.legalLink}>
                            {translationFooter.privacyPolicy.label}
                        </Typography>
                    </Link>
                    <Link href={"#"}>
                        <Typography variant={"h6"} className={classes.legalLink}>
                            {translationFooter.sitemap.label}
                        </Typography>
                    </Link>
                </Box>
                <Typography variant={"body1"} className={classes.bottomPanelContainerCreatedBy}>{translationFooter.designedBy.label}</Typography>
            </Box>
        </StyledFooter>
    );
};
