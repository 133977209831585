import React from "react";
import {styled} from '@mui/material/styles';
import {classes} from "./construction-elements-content-table-style"
import {LocaleContext} from "../../../context/locale-context/locale-context";
import translate from "../../../language/translate";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {Typography} from "@mui/material";

const StyledConstructionElementsContentTable = styled(TableContainer)(({theme}) => ({
    paddingTop: 20,
    [theme.breakpoints.up('sm')]: {
        maxHeight: '30vh',
    },

    [`& .${classes.actionsWrapper}`]: {
        flexDirection: "row",
        minHeight: 'inherit',
    },
    [`& .${classes.tableHeader}`]: {
        '& th': {
            [theme.breakpoints.down('sm')]: {
                paddingRight: 5,
            },
            paddingLeft: 0,
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    [`& .${classes.tableRow}`]: {
        '&:first-of-type th': {
            color: theme.palette.primary.main,
        },
        '&:last-child td, &:last-child th': {
            border: 0
        },
        '& th': {
            [theme.breakpoints.down('sm')]: {
                paddingRight: 5,
            },
            paddingLeft: 0,
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    [`& .${classes.tableHeaderName}`]: {
        fontWeight: 'bold',
        color: theme.palette.secondary.main,
    },
    [`& .${classes.tableHeaderContent}`]: {
        textAlign: 'right',
        color: theme.palette.secondary.main,
    },
}));

function ConstructionElementsContentTable() {
    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    const {tableHeader, fuelVariants} = translation.offer.actions.content.table;

    return (
        <StyledConstructionElementsContentTable>
            <Table aria-label="Tabela kosztów">
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        {tableHeader.map((header, index) => (
                            <TableCell key={index}>
                                <Typography variant={"body1"} className={classes.tableHeaderName}
                                            sx={{textAlign: index !== 0 ? "right" : "left"}}>
                                    {header}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fuelVariants.map((fuelVariant, index) => (
                        <TableRow key={fuelVariant.fuelName}
                                  className={classes.tableRow}
                        >
                            <TableCell component="th" scope="row" style={{display: "flex", alignItems: "center"}}>
                                {fuelVariant.icon}&nbsp;
                                <Typography variant={"body1"} className={classes.tableHeaderContent}>
                                    {fuelVariant.fuelName}
                                </Typography>
                            </TableCell>
                            <TableCell component="th" align="right">
                                <Typography variant={"body1"} className={classes.tableHeaderContent}>
                                    {fuelVariant.surfaceArea}&nbsp;m<sup>2</sup>
                                </Typography>
                            </TableCell>
                            <TableCell component="th" align="right">
                                <Typography variant={"body1"} className={classes.tableHeaderContent}
                                            sx={{textAlign: index !== 0 ? "right" : "left"}}>
                                    {fuelVariant.value}
                                </Typography>
                            </TableCell>
                            <TableCell component="th" align="right">
                                <Typography variant={"body1"} className={classes.tableHeaderContent}>
                                    {fuelVariant.price}&nbsp;zł
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledConstructionElementsContentTable>
    )
        ;
}

export default ConstructionElementsContentTable;
