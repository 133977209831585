const PREFIX = 'gallery-modal';

export const classes = {
    overlay: `${PREFIX}-overlay`,
    closeArea: `${PREFIX}-close-area`,
    slider: `${PREFIX}-slider`,
    slide: `${PREFIX}-slide`,
    goLeft: `${PREFIX}-go-left`,
    goRight: `${PREFIX}-go-right`,
    close: `${PREFIX}-close`,
    carouselButtonArrow: `${PREFIX}-carousel-button-arrow`,
    carouselButtonArrowPrev: `${PREFIX}-carousel-button-arrow-prev`,
    carouselButtonArrowNext: `${PREFIX}-carousel-button-arrow-next`
};
