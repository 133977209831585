export const displayDays = {
    shift: 4,
    small: 1,
    medium: 2,
    large: 4
};

export const recalculateAvailabilityDays = (): Date[] => {
    const days = [];
    const today = new Date();
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    let dayCount = 0;

    while (days.length < 4) { // Default shift value
        const nextDay = new Date(today.getTime() + dayCount * oneDayInMillis);
        const dayOfWeek = nextDay.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude weekends
            days.push(nextDay.toISOString().split('T')[0]); // Format as YYYY-MM-DD
        }
        dayCount++;
    }

    return days;
};
