const PREFIX = 'technician';

export const classes = {
    header: `${PREFIX}-header`,
    navLink: `${PREFIX}-nav-link`,
    main: `${PREFIX}-main`,
    serviceOrderCard: `${PREFIX}-service-order-card`,
    technicianCard: `${PREFIX}-technician-card`,
    icon: `${PREFIX}-icon`,
    infoRow: `${PREFIX}-info-row`,
    nav: `${PREFIX}-nav`,
    actionButtons: `${PREFIX}-action-buttons`,
};
