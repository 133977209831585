import {styled} from '@mui/material/styles';
import React, {useState} from "react";

import {Box} from "@mui/material";
import ContactInput from "../contact-input/contact-input";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import type {Address} from "../shared/dto/address";

const StyledContactAddress = styled(Box)(({theme}) => ({
    display: 'contents'
}));

const ContactAddress = ({inverseColors, contact, onContactChange}) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;

    const [address: Address, onAddressChange] = useState({...contact.address});

    const formChanged = (event) => {
        const addressAfterChange: Address = {
            ...address,
            [event.target.id]: event.target.value
        };
        onAddressChange(addressAfterChange);
        onContactChange({...contact, address: addressAfterChange});
    };

    return (
        <StyledContactAddress>
            <ContactInput id={'city'}
                          inverseColors={inverseColors}
                          value={address.city}
                          onChange={formChanged}
                          label={translatedContact.form.city.label}
                          inputPlaceholder={translatedContact.form.city.placeholder}
            />
            <ContactInput id={'street'}
                          inverseColors={inverseColors}
                          value={address.street}
                          onChange={formChanged}
                          label={translatedContact.form.street.label}
                          inputPlaceholder={translatedContact.form.street.placeholder}
            />
            <ContactInput id={'postalCode'}
                          inverseColors={inverseColors}
                          value={address.postalCode}
                          onChange={formChanged}
                          label={translatedContact.form.postalCode.label}
                          inputPlaceholder={translatedContact.form.postalCode.placeholder}
            />
            <ContactInput id={'house'}
                          inverseColors={inverseColors}
                          value={address.house}
                          onChange={formChanged}
                          label={translatedContact.form.house.label}
                          inputPlaceholder={translatedContact.form.house.placeholder}
            />
            <ContactInput id={'houseNumber'}
                          inverseColors={inverseColors}
                          value={address.houseNumber}
                          onChange={formChanged}
                          label={translatedContact.form.houseNumber.label}
                          inputPlaceholder={translatedContact.form.houseNumber.placeholder}
            />
        </StyledContactAddress>
    );
}

export default ContactAddress;
