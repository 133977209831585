import React from "react";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {classes} from "./footer-menu-left-styles"
import translate from "../../../language/translate";
import {Link} from "@mui/material";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {styled} from '@mui/material/styles';

const StyledFooterMenuLeft = styled(ImageList)(({theme}) => ({
    minWidth: '100%',
    alignItems: "center",

    [`& .${classes.info}`]: {
        ...theme.typography.caption,
        color: theme.palette.primary.background,
        textAlign: 'center',
    },
    [`& .${classes.displayInfo}`]: {
        minWidth: '100%',
        alignItems: "center",
    },
    [`& .${classes.logoImgWrapper}`]: {
        textAlign: 'center'
    },
    [`& .${classes.logoImg}`]: {
        maxHeight: '80px',
        maxWidth: '70%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '90%',
        }
    },
}))

const FooterMenuLeft = (props) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];

    return (
        <StyledFooterMenuLeft cols={1}
                              rowHeight={"auto"}
        >
            <ImageListItem key={'footer-logo'}
                           className={classes.displayInfo}
            >
                <Link href={translation.home.url} className={classes.logoImgWrapper}>
                    <img src={"/assets/logo_3_pr.png"} alt={translation.company.title} className={classes.logoImg}/>
                </Link>
            </ImageListItem>
            <Hidden mdDown>
                <ImageListItem key={'footer-info-text'}
                               className={classes.displayInfo}
                >
                    <Typography variant={"body1"} className={classes.info}>{props.infoText}</Typography>
                </ImageListItem>
            </Hidden>
        </StyledFooterMenuLeft>
    );
}

export default FooterMenuLeft;
