import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import translate from '../../language/translate';
import ContactInfo from "./contact-info/contact-info";
import type {ContactData} from "./shared/dto/contact-data";
import {classes} from "./contact-styles";
import {classes as classesApp} from "../app-styles";
import {LocaleContext} from "../../context/locale-context/locale-context";
import {defaultLocale} from "../../context/locale-context/default-locale";
import ContactAddress from "./contact-address/contact-address";
import ContactPersonalData from "./contact-personal-data/contact-personal-data";
import {useContactApi} from "./api/contact-api";
import {AppContext} from "../../context/app-context/app-context";
import {ServiceOrderContext} from "../../context/service-order-context/service-order-context";
import {useNavigate} from "react-router-dom";

export const StyledContact = styled(Box)(({theme}) => ({
    [`& .${classes.form}`]: {
        display: 'contents'
    },
}));

const Contact = ({extended, children}) => {

    const {sendEmail, createOrder} = useContactApi();
    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;
    const {setInfo} = React.useContext(AppContext);
    const {serviceOrderData} = React.useContext(ServiceOrderContext);
    const navigate = useNavigate();

    const [contact: ContactData, onContactChange] = useState({
        address: {
            city: '',
            street: '',
            postalCode: '',
            house: '',
            houseNumber: '',
        },
        personalData: {
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
        }
    });

    const submit = async (event) => {
        event.preventDefault();
        if (extended) {
            await createOrder(contact, serviceOrderData).then(() => setInfo(translatedContact.action.order.success));
        } else {
            await sendEmail(contact).then(() => setInfo(translatedContact.action.email.success));
        }
        navigate('/');
    }

    const extendedSection = (extended) => {
        if (extended) {
            return (children)
        } else {
            return (<ContactInfo
                label={translatedContact.label}
                shortInfo={translatedContact.shortInfo}
                info={translatedContact.info}
            />)
        }
    }

    return (
        <StyledContact id='contact'
                       className={classesApp.top}
        >
            <Box
                position={'relative'}
                flexDirection='row'
                display={'flex'}
                justifyContent='center'
            >
                <Grid container={true}
                      component={"form"}
                      noValidate
                      autoComplete="off"
                      onSubmit={submit}
                      justifyContent='center'
                >
                    <Grid container
                          item
                          xs={12} sm={12} md={extended ? 3 : 6} lg={extended ? 3 : 6} xl={extended ? 3 : 6}
                          direction={'column'}
                          justifyContent={'center'}
                    >
                        {extendedSection(extended)}
                    </Grid>
                    <Grid container
                          item
                          xs={12} sm={12} md={6} lg={6} xl={6}
                          justifyContent={extended ? 'flex-start' : 'center'}
                          alignItems={'center'}
                    >
                        {extended &&
                            <ContactAddress inverseColors={extended}
                                            contact={contact}
                                            onContactChange={onContactChange}
                            />
                        }
                        <ContactPersonalData inverseColors={extended}
                                             contact={contact}
                                             onContactChange={onContactChange}
                        />
                    </Grid>
                </Grid>
            </Box>
        </StyledContact>
    );
}

export default Contact;
