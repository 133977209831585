import React from "react";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import {styled} from "@mui/material/styles";
import {classes} from "./footer-menu-social-provider-link-styles";
import Grid from "@mui/material/Grid";
import {Link} from "@mui/material";

const StyledFooterMenuSocialProviderLink = styled(Grid)(({theme}) => ({
    flexDirection: 'row',
    [`& .${classes.iconWrapper}`]: {
        display: 'flex',
        color: theme.palette.secondary.background,
        cursor: 'pointer',
        borderRadius: 10,
        [`& :hover`]: {
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
        },
        [`& a`]: {
            color: theme.palette.secondary.background,
            textDecoration: 'none',
        }
    },
    [`& .${classes.icon}`]: {
        fontSize: 'x-large',
        padding: 10,
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: 'large',
        },
    },
}));

const FooterMenuSocialProviderLink = () => {
    return (
        <StyledFooterMenuSocialProviderLink container>
            <Grid item className={classes.iconWrapper}>
                <Link href={'http://www.twitter.pl'}>
                    <TwitterIcon className={classes.icon}/>
                </Link>
            </Grid>
            <Grid item className={classes.iconWrapper}>
                <Link href={'https://www.facebook.com/beaverPlumbers'}>
                    <FacebookOutlinedIcon className={classes.icon}/>
                </Link>
            </Grid>
            <Grid item className={classes.iconWrapper}>
                <Link href={'http://www.instagram.com'}>
                    <InstagramIcon className={classes.icon}/>
                </Link>
            </Grid>
        </StyledFooterMenuSocialProviderLink>
    )
}

export default FooterMenuSocialProviderLink;

