import {styled} from '@mui/material/styles';
import React, {useState} from "react";

import {Box} from "@mui/material";
import ContactInput from "../contact-input/contact-input";
import translate from "../../../language/translate";
import {defaultLocale} from "../../../context/locale-context/default-locale";
import {LocaleContext} from "../../../context/locale-context/locale-context";
import ContactInputText from "../contact-input-text/contact-input-text";
import ContactButton from "../contact-button/contact-button";
import type {PersonalData} from "../shared/dto/personal-data";

const StyledContactPersonalData = styled(Box)(({theme}) => ({
    display: 'contents'
}));

const ContactPersonalData = ({inverseColors, contact, onContactChange}) => {

    const {localeContext} = React.useContext(LocaleContext);
    const translation = translate[localeContext === undefined ? defaultLocale.value : localeContext.value];
    let translatedContact = translation.contact;

    const [personalData: PersonalData, onPersonalDataChange] = useState({...contact.personalData});

    const formChanged = (event) => {
        const personalDataAfterChange: PersonalData = {
            ...personalData,
            [event.target.id]: event.target.value
        };
        onPersonalDataChange(personalDataAfterChange);
        onContactChange({...contact, personalData: personalDataAfterChange});
    };

    return (
        <StyledContactPersonalData>
            <ContactInput id={'name'}
                          inverseColors={inverseColors}
                          value={personalData.name}
                          onChange={formChanged}
                          label={translatedContact.form.name.label}
                          inputPlaceholder={translatedContact.form.name.placeholder}
            />
            <ContactInput id={'email'}
                          inverseColors={inverseColors}
                          value={personalData.email}
                          onChange={formChanged}
                          label={translatedContact.form.email.label}
                          inputPlaceholder={translatedContact.form.email.placeholder}
            />
            <ContactInput id={'phoneNumber'}
                          inverseColors={inverseColors}
                          value={personalData.phoneNumber}
                          onChange={formChanged}
                          label={translatedContact.form.phone.label}
                          inputPlaceholder={translatedContact.form.phone.placeholder}
            />
            <ContactInputText id={'message'}
                              inverseColors={inverseColors}
                              value={personalData.message}
                              onChange={formChanged}
                              label={translatedContact.form.message.label}
                              inputPlaceholder={translatedContact.form.message.placeholder}
                              inputPlaceholderMessage={translatedContact.form.message.placeholderMessage}
            />
            <ContactButton id={'send-button'}
                           label={translatedContact.form.sendButton.label}
            />
        </StyledContactPersonalData>
    );
}

export default ContactPersonalData;
