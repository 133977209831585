const PREFIX = 'construction-elements';

export const classes = {
    imageWrapper: `${PREFIX}-image-wrapper`,
    imageContainer: `${PREFIX}-image-container`,
    imageWrapperTopBox: `${PREFIX}-image-wrapper-top-box`,
    imageWrapperBottomBox: `${PREFIX}-image-wrapper-bottom-box`,
    contentWrapper: `${PREFIX}-content-wrapper`,
    content: `${PREFIX}-content`,
    buttonContainer: `${PREFIX}-button-container`,
    contactActionButton: `${PREFIX}-contact-action-button`,
};
