const commonTranslate = {
    enUS: {
        company: 'Beaver Plumbers',
        email: 'admin@beaver-plumbers.com',
        phone: '+48 537 777 308',
        currency: '€',
        action: {
            contactButton: 'Contact us',
        },
        gallery: {
            imagesPath: '/assets/gallery-content/',
        },
    },
    plPL: {
        company: 'Beaver Plumbers',
        email: 'admin@beaver-plumbers.com',
        phone: '+48 537 777 308',
        currency: 'zł',
        action: {
            contactButton: 'Umów instalację',
        },
        gallery: {
            imagesPath: '/assets/gallery-content/',
        },
    }
}

export default commonTranslate;
